import React, { useState } from "react"
import { Button, Form, Input, Modal } from "antd"
import css from "./SalesModal.module.css"
import firebaseFunctions from "../../state/firebase/functions"

const SalesModal = () => {
  const [salesModalVisible, setSalesModalVisible] = useState(false)

  const onFinish = (values) => {
    setSalesModalVisible(false)
    firebaseFunctions.onSandboxSalesRequest(values)
  }

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo)
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  }
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  }
  return (
    <div>
      <div onClick={() => setSalesModalVisible(true)} className={css.salesCta}>
        Contact Tracis
      </div>
      <Modal
        title="Contact Tracis"
        visible={salesModalVisible}
        onCancel={() => setSalesModalVisible(false)}
        maskStyle={{ backdropFilter: "blur(8px)" }}
        footer={false}
      >
        <div>
          {`We're excited to talk to you. Give us your details and we'll get back to
    you immediately!`}
        </div>
        <br />
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input your full name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Company"
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please input your company name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: false,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Notes"
            name="notes"
            rules={[
              {
                required: false,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input.TextArea ows={4} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default SalesModal
