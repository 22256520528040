// Match Python timestamp format: "%Y-%m-%d_%H-%M-%S".
export const generateScanID = () => {
  const now = new Date()
  const Y = `${now.getUTCFullYear()}`
  const m = `${now.getUTCMonth() + 1}`.padStart(2, 0)
  const d = `${now.getUTCDate()}`.padStart(2, 0)
  const H = `${now.getUTCHours()}`.padStart(2, 0)
  const M = `${now.getUTCMinutes()}`.padStart(2, 0)
  const S = `${now.getUTCSeconds()}`.padStart(2, 0)
  return `scan_${Y}-${m}-${d}_${H}-${M}-${S}`
}
