import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Checkbox } from "antd"
import Header from "../../common/Header"
import { useGlobalState } from "../../../state"
import x from "../../../images/x.png"
import o from "../../../images/o.png"

import css from "./index.module.css"
import { STEPS } from "../../../state/constants"

export default function UnknownLens({ setStep }) {
  const [frontChecked, setFrontChecked] = useState(false)
  const [rearChecked, setRearChecked] = useState(false)
  const [state] = useGlobalState()

  const disabled = !(frontChecked && rearChecked)

  return (
    <div>
      <Header>Lens Not Previously Scanned</Header>
      <p>The lens you entered hasn’t previously been scanned.</p>
      <p>
        {`${state.scanner.human_name}`} needs a bit more time and information to
        accurately characterize this lens.
      </p>
      <p>
        Using your Tracis provided wax pencil, mark a circle (O) on the front
        element and a cross (X) on the rear, near to the center of the lens.
      </p>
      <div className={css.lensFrontandBack}>
        <div className={css.lens}>
          <div className={css.surfaceHeading}>Front</div>
          <div className={css.circleContainer}>
            <div className={css.bigCircle}>
              <img src={o} alt="o drawn on front lens element" height={40} />
            </div>
          </div>
          <Checkbox onChange={(e) => setFrontChecked(e.target.checked)}>
            confirm front marked
          </Checkbox>
        </div>
        <div className={css.lens}>
          <div className={css.surfaceHeading}>Rear</div>
          <div className={css.circleContainer}>
            <div className={css.smallCircle}>
              <img src={x} alt="x drawn on rear lens element" height={30} />
            </div>
          </div>
          <Checkbox onChange={(e) => setRearChecked(e.target.checked)}>
            confirm rear marked
          </Checkbox>
        </div>
      </div>
      <p>
        This process helps {`${state.scanner.human_name}`} lock onto the front
        and rear element to provide the best possible scan analysis. Future
        scans of this lens will not require this step.
      </p>
      <p>It can take up to 24 hours to accurately characterize a lens.</p>
      <p>
        Remove the markings and scan the lens again to get a certified scan.
      </p>
      <p>Thank you for helping to improve the Tracis ecosystem!</p>
      <div className={css.continueButton}>
        <Button
          type="primary"
          ghost
          disabled={disabled}
          onClick={() => setStep(STEPS.FINAL_CHECKLIST)}
          block
        >
          {disabled ? "Confirm Front Marked and Rear Marked" : "Continue"}
        </Button>
      </div>
    </div>
  )
}

UnknownLens.propTypes = {
  /** @type{Function} Set ScanWizard step. */
  setStep: PropTypes.func,
}
