export const defaultState = {
  addLensModalVisible: false,
  BETA_USER: false,
  calibrationScan: false,
  collapsed: true,
  currentScanLens: null,
  demoMode: false,
  invitedUser: false,
  lensTypes: [],
  loaded: true,
  sandbox: false,
  scans: [],
  scanners: [],
  shareModalVisible: false,
  step: 0,
  userOrgId: undefined,
  userOrgIds: [],
}
