import { COLLECTION } from "../constants"
import { getDb, arraysEqual, sleep, getAuth } from "./helpers"

const organizations = {
  onOrgsForUser: async (uuid, onChange) => {
    console.info(`Fetching user orgs for user ${uuid}...`)
    const db = await getDb()
    return db
      .collection(COLLECTION.USERS)
      .doc(uuid)
      .onSnapshot(async (snapshot) => {
        if (!snapshot.exists) return

        const updatedOrgs = snapshot.data().organizations
        // Compare the orgs to the list of organizations currently in our JWT.
        // If lists don't match, re-request the token until they do match.
        let retries = 0
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (retries > 10) {
            const err = new Error(
              `Token still not refreshed after ${retries} retries; giving up.`
            )
            throw err
          }
          // Get the JWT as currently stored in memory (doesn't update the token).
          const auth = await getAuth()
          const currentToken = await auth.currentUser.getIdTokenResult()
          const currentOrgs = currentToken.claims.organizations
          if (arraysEqual(currentOrgs, updatedOrgs)) break // Awesome, Firestore matches the JWT.
          // Firestore doesn't match Firebase Auth's JWT.
          console.info(
            `Organizations in Firestore don't match Auth: '${currentOrgs}' 
              vs. '${updatedOrgs}.' Refreshing token...`
          )
          if (retries++ > 0) {
            await sleep(5000) // Wait 5 seconds to give Auth time to catch up to Firestore.
          }
          await auth.currentUser.getIdToken(true) // Refresh token.
        }
        // With Firestore and Auth in sync, we can tell the user the happy news.
        console.info(
          `User has access to organizations: ${JSON.stringify(updatedOrgs)}`
        )
        onChange(updatedOrgs)
      })
  },
  getOrgDetails: async (orgIds) => {
    const db = await getDb()
    let orgPromises = []
    for (var i = 0; i < orgIds.length; i++) {
      orgPromises.push(
        db.collection(COLLECTION.ORGANIZATIONS).doc(orgIds[i]).get()
      )
    }
    orgPromises = await Promise.all(orgPromises)
    return orgPromises.map((org) => org.data())
  },
  updateOrgName: async (orgId, name) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .update({ name: name })
  },
  createNewOrg: async (userId, orgName) => {
    const db = await getDb()
    const userRef = db.collection(COLLECTION.USERS).doc(userId)
    let userDoc = await userRef.get()
    const addedOrgId = db.collection(COLLECTION.ORGANIZATIONS).doc().id
    let { organizations, owned_organizations } = userDoc.data()
    owned_organizations = owned_organizations || []
    await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(addedOrgId)
      .set({ name: orgName, _id: addedOrgId, users: [userRef] })
      .then(() => {
        organizations.push(addedOrgId)
        owned_organizations.push(addedOrgId)
      })
    return await db.collection(COLLECTION.USERS).doc(userId).update({
      organizations: organizations,
      owned_organizations: owned_organizations,
    })
  },
  onOrgUsers: async (orgId, onModify) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .onSnapshot(async (snapshot) => {
        const orgData = snapshot.data()
        const userRefs = orgData.users
        const users = await Promise.all(userRefs.map((u) => u.get()))
        const userData = users.map((u) => {
          return { ...u.data(), id: u.id }
        })
        onModify(userData)
      })
  },
}

export default organizations
