/* ================================= LENS ================================= */
export interface Lens {
  make: string
  model: string
  barcode?: string
  entrance_pupil?: number
  focal_length_description?: string
  fstop?: number
  tstop?: number
  housing_make?: string
  image_diameter?: number
  length?: number
  mount?: string[]
  serial_number: string
}

export interface LensDocument {
  lens_id: string
  lens_type_ref: string
  serial_number: string
  barcode?: string
}

export interface LensInstance {
  serial_number: string
  barcode?: string
}

/* =========================== FORM VALIDATION =========================== */
export const SUCCESS = "success"
export type SUCCESS = typeof SUCCESS
export const ERROR = "error"
export type ERROR = typeof ERROR
export const SN_EXISTS = "Serial number already exists in your inventory."
export type SN_EXISTS = typeof SN_EXISTS
export const BARCODE_EXISTS = "Barcode already exists in your inventory."
export type BARCODE_EXISTS = typeof BARCODE_EXISTS
export const NULL = null
export type NULL = typeof NULL

export type Validation = {
  validateStatus: SUCCESS | ERROR
  errorMsg: SN_EXISTS | BARCODE_EXISTS | NULL
}
