import React, { useState, useRef, useEffect } from "react"
import { Button } from "antd"
import cx from "classnames"
import CommonTimeline from "../common/Timeline"
import { SketchField, Tools } from "react-sketch"
import ControlPanel from "./ControlPanel"
import SurfaceSelect from "../common/SurfaceSelect"
import { SCRATCH_COLORS } from "../../state/constants"
import lensOutline from "../../images/lens_outline.svg"
import scratchesApi from "../../state/firebase/scratches"
import { sortTimestamp } from "../../state/firebase/helpers"
import { useGlobalState } from "../../state"
import css from "./index.module.css"

export default function Scratches() {
  const [state] = useGlobalState()
  const [controls, setControls] = useState({
    tool: Tools.Pencil,
    color: SCRATCH_COLORS.WHITE,
    lineWidth: 3,
    expanded: false,
    open: false,
  })
  // TODO: extend to allow for the addition of surfaces.
  const [surfaces] = useState(["Front", "Rear"])
  const [currentSurface, setCurrentSurface] = useState("Front")
  const [hoverScratch, setHoverScratch] = useState()
  const [sketchValue, setSketchValue] = useState({ front: {}, rear: {} })
  const [numNewScratches, setNumNewScratches] = useState(0)
  const [editing, setEditing] = useState(false)
  const [pending, setPending] = useState(false)
  const [scratches, setScratches] = useState([])

  const ref = useRef(null)

  useEffect(() => {
    const unsubscribe = scratchesApi.onScratches(
      state.userOrgId,
      state.inventoryLens.lens_id,
      (scratches) => {
        const labeledScratches = scratches.map((scratch) => ({
          type: "scratch",
          ...scratch,
        }))
        setScratches(sortTimestamp(labeledScratches))
      }
    )

    return () => unsubscribe.then((unsub) => unsub())
  }, [])

  const undo = () => {
    ref.current.canUndo() && ref.current.undo()
    setNumNewScratches(ref.current.toJSON().objects.length)
  }

  const redo = () => {
    ref.current.canRedo() && ref.current.redo()
    // You'd think we could setNumNewScratches the way we do in undo but,
    // after an undo() action, objects.length gives the wrong number of objects.
    // Simply adding 1 to the prior number is more reliable.
    setNumNewScratches(numNewScratches + 1)
  }

  const handleAddScratch = () => {
    const newScratchPaths = ref.current.toJSON().objects
    if (newScratchPaths.length === 0) {
      setPending(false)
      setEditing(false)
      return
    }
    setPending(true)
    scratchesApi.addScratch(
      state.userOrgId,
      state.inventoryLens.lens_id,
      ref.current.toDataURL(),
      currentSurface,
      (finished) => {
        setEditing(finished)
        setPending(finished)
      },
      state.uid,
      state.userName
    )
  }

  const handleSurfaceFilterChange = (surface) => {
    if (surface === "Front") {
      ref.current &&
        setSketchValue({ front: sketchValue.front, rear: ref.current.toJSON() })
    }

    if (surface === "Rear") {
      ref.current &&
        setSketchValue({ rear: sketchValue.rear, front: ref.current.toJSON() })
    }
    setCurrentSurface(surface)
  }

  const handleEditSave = () => {
    editing ? handleAddScratch() : setEditing(true)
  }

  const deleteScratch = (scratchId) => {
    setPending(true)
    scratchesApi
      .deleteScratch(state.userOrgId, state.inventoryLens.lens_id, scratchId)
      .then(() => setPending(false))
  }

  let surfaceScratches = scratches.filter(
    (scratch) => scratch.surface === currentSurface
  )

  if (surfaceScratches.length === 0) {
    surfaceScratches = [
      {
        id: "placeholder",
        type: "scratch",
        timestamp: { seconds: new Date() / 1000 },
      },
    ]
  }

  return (
    <div className={css.container}>
      <div className={cx(css.surfaceSelect, { [css.editingSurface]: editing })}>
        <SurfaceSelect
          surface={currentSurface}
          surfaces={surfaces}
          handleSurfaceFilterChange={handleSurfaceFilterChange}
        />
      </div>
      <div
        className={cx(css.scratchDiagram, {
          [css.expanded]: controls.expanded,
        })}
      >
        {surfaceScratches.length > 0 && (
          <div className={css.scratchTimeline}>
            <CommonTimeline
              items={surfaceScratches}
              deleteScratch={deleteScratch}
              onItemEnter={(id) => setHoverScratch(id)}
              onItemLeave={() => setHoverScratch()}
            />
          </div>
        )}
        <div className={css.sketchContainer}>
          {scratches &&
            surfaceScratches.map((scratch) => (
              <img
                key={scratch.id}
                className={cx(css.sketchField, {
                  [css.unhighlightedScratch]:
                    hoverScratch && scratch.id !== hoverScratch,
                })}
                src={scratch.imageLink}
                alt="scraches"
              />
            ))}
          <img
            className={cx(css.sketchField, {
              [css.flip]: currentSurface === "Rear",
            })}
            src={lensOutline}
            alt="lens outline"
          />
          {editing && (
            <SketchField
              width={"100%"}
              height={"100%"}
              lineColor={controls.color}
              undo={undo}
              tool={controls.tool}
              redo={redo}
              lineWidth={controls.lineWidth}
              value={
                currentSurface === "Rear" ? sketchValue.rear : sketchValue.front
              }
              ref={ref}
              onChange={() =>
                ref.current &&
                setNumNewScratches(ref.current.toJSON().objects.length)
              }
              className={css.sketchField}
            />
          )}
        </div>
      </div>
      <div
        className={cx(css.controlPanelWrapper, { [css.inactive]: !editing })}
      >
        <ControlPanel
          controls={controls}
          setControls={setControls}
          undo={undo}
          redo={redo}
          numScratches={numNewScratches}
          handleAddScratch={handleAddScratch}
          saving={pending}
        />
      </div>
      <div className={cx(css.edit, { [css.editing]: editing })}>
        <Button
          loading={pending}
          onClick={handleEditSave}
          type={editing ? "primary" : "secondary"}
          id="tour-step-4"
        >
          {editing ? "Save" : "Edit"}
        </Button>
      </div>
    </div>
  )
}
