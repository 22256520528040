import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import AppPromise from "./components/layout/App"
import * as serviceWorker from "./serviceWorker"
import { StateProvider } from "./state"
import { defaultState } from "./state/defaultState"
import { reducer } from "./state/reducer"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

async function main() {
  if (
    process.env.REACT_APP_ID !== "" &&
    process.env.REACT_APP_ID !== "dev" &&
    process.env.NODE_ENV !== "development"
  ) {
    let datetime = new Date()
    datetime = datetime.toISOString().split(".")[0]
    Sentry.init({
      dsn:
        "https://81df4383cfc3402e9bb32d8a709b94e6@o490285.ingest.sentry.io/5553965",
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      debug: true,
      tracesSampleRate: 0.5,
      release: "platform@" + datetime,
      environment: process.env.REACT_APP_ID,
    })
  }

  console.log("Current environment ", process.env.REACT_APP_ID)

  const App = await AppPromise
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <StateProvider initialState={defaultState} reducer={reducer}>
        <App />
      </StateProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register()
}

main()
