import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import {
  // Affix,
  Anchor,
  Divider,
  // PageHeader,
  // DatePicker,
  Row,
  Col,
  Empty,
} from "antd"
import css from "./index.module.css"
import cx from "classnames"
import InfiniteScroll from "react-infinite-scroller"
// import ScanHistoryFilter from "./ScanHistoryFilter"
import ScanCard from "../common/ScanCard"
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleFilled,
  StopFilled,
} from "@ant-design/icons"
import { SCAN_STATUS } from "../../state/constants"
import { generateDateString } from "../../helpers/date"
import Loading from "../common/Loading"

// Turn all scans into a string on status and ID to which useEffect can listen for
// changes. Because of the size and complexity of an array of Firestore documents,
// React was not picking up changes sent from the onScansChange listener. NOTE:
// this format means we're currently only listening for ID (add, delete) and status
// updates.
// const summarizeScans = (scans) => {
//   return (
//     scans.map((scan) => scan.status).join(",") +
//     scans.map((scan) => scan._id).join(",")
//   )
// }

const ScanHistory = () => {
  const [state, dispatch] = useGlobalState()
  // const [orgUsers, setOrgUsers] = useState([]
  // const [scanners, setScanners] = useState([])
  // const [scans, setScans] = useState([])
  const [getNextScans, setGetNextScans] = useState()
  const [hasMore, setHasMore] = useState(true)
  const [scansFetched, setScansFetched] = useState(false)
  const [scansGrouped, setScansGrouped] = useState(false)
  const [scannerFilter] = useState(undefined)
  const [statusFilter] = useState(undefined)
  const [userFilter] = useState(undefined)
  const [dateFilter] = useState([])
  //
  // const { RangePicker } = DatePicker

  const groupByDate = (scans) => {
    let dates = {}
    scans.forEach((scan) => {
      const day = generateDateString(scan.timestamp.seconds)
      if (dates[day]) {
        dates[day].push(scan)
      } else {
        dates[day] = [scan]
      }
    })
    return dates
  }

  // OnScansChange callback upon scan creation.
  // const onAdded = (scanDoc) => {
  //   if (scans.find((scan) => scan._id === scanDoc._id) !== undefined) {
  //     return
  //   }
  //   scans.unshift(scanDoc)
  //   setScans(scans)
  // }

  // OnScansChange callback for any scan field being modified.
  // const onModified = (scan) => {
  //   const scanIndex = scans.map((x) => x._id).indexOf(scan._id)
  //   scans[scanIndex] = scan
  //   setScans(scans)
  // }

  // OnScansChange callback for after all scans have been loaded.
  // const onDone = () => {
  //   // Generate lists of unique users, scanners for Select filter options.
  //   const usersSet = new Set()
  //   const scannersSet = new Set()
  //   const usersResult = []
  //   const scannersResult = []
  //   for (const scan of scans) {
  //     // Build up Filter options.
  //     if (!usersSet.has(scan.user.doc_id)) {
  //       usersSet.add(scan.user.doc_id)
  //       usersResult.push({
  //         name: scan.user.displayName ? scan.user.displayName : scan.user.email,
  //         doc_id: scan.user.doc_id,
  //       })
  //     }
  //     if (!scannersSet.has(scan.scanner.doc_id)) {
  //       scannersSet.add(scan.scanner.doc_id)
  //       scannersResult.push({
  //         name: scan.scanner.human_name,
  //         doc_id: scan.scanner.doc_id,
  //       })
  //     }
  //   }
  //   setOrgUsers(usersResult)
  //   setScanners(scannersResult)
  //   setScansFetched(true)
  // }

  useEffect(() => {
    if (state.userOrgId === undefined) return

    db.getScansPaginated(state.userOrgId).then(
      ({ incomingScans, getNextScans }) => {
        dispatch({ type: "SET_SCANS", scans: incomingScans })
        setGetNextScans(getNextScans)
        setScansFetched(true)
        setScansGrouped(true)
      }
    )
  }, [state.userOrgId])

  const loadMoreScans = () => {
    getNextScans.then(({ incomingScans, getNextScans, hasMoreScans }) => {
      dispatch({ type: "SET_SCANS", scans: [...state.scans, ...incomingScans] })
      setGetNextScans(getNextScans)
      setHasMore(hasMoreScans)
    })
  }

  // Filter handlers.
  // const handleStatusFilterChange = (value) => {
  //   setStatusFilter(value)
  // }

  // const handleScannerFilterChange = (value) => {
  //   setScannerFilter(value)
  // }

  // const handleUserFilterChange = (value) => {
  //   setUserFilter(value)
  // }

  // const handleDateFilterChange = (date) => {
  //   const start = Date.parse(date[0]) / 1000
  //   const end = Date.parse(date[1]) / 1000

  //   if (isNaN(start) || isNaN(end)) {
  //     setDateFilter([])
  //     return
  //   }

  //   setDateFilter([start, end])
  // }

  // Change card text, icon color, popover text based on scan status.
  const mapStatusToVisualInfo = (status) => {
    switch (status) {
      case SCAN_STATUS.PROCESSED:
      case SCAN_STATUS.READY_TO_REVIEW:
        return {
          icon: <CheckCircleFilled className={cx("green", css.icon)} />,
          friendlyStatus: "Report Ready",
          explanation:
            "Tracis has finished scanning this lens and its report is available for viewing.",
        }
      case SCAN_STATUS.BAD:
        return {
          icon: <CloseCircleFilled className={cx("red", css.icon)} />,
          friendlyStatus: "Error",
          explanation:
            "There was an error and this scan was not able to be processed.",
        }
      case SCAN_STATUS.CALIBRATED:
        return {
          icon: <CheckCircleFilled className={cx("blue", css.icon)} />,
          friendlyStatus: "Calibrated",
          explanation: "Lens calibrated. Scan again for a full report.",
        }
      case SCAN_STATUS.PARTIALLY_PROCESSED:
        return {
          icon: <CheckCircleFilled className={cx("yellow", css.icon)} />,
          friendlyStatus: "Partial Report",
          explanation: "Report available for some surfaces, not all.",
        }
      case SCAN_STATUS.STOPPED:
        return {
          icon: <StopFilled className={cx("yellow", css.icon)} />,
          friendlyStatus: "Cancelled",
          explanation: "Scan was cancelled by the user.",
        }
      default:
        return {
          icon: <ClockCircleFilled className={cx("purple", css.icon)} />,
          friendlyStatus: "Processing",
          explanation:
            "Tracis is in the process of analyzing this scan. Stay tuned!",
        }
    }
  }

  // Scan field Select filter on status.
  // const statusSelect = (
  //   <ScanHistoryFilter
  //     key="statusFilter"
  //     options={[
  //       { value: SCAN_STATUS.PROCESSED, title: "Report Ready" },
  //       { value: SCAN_STATUS.PARTIALLY_PROCESSED, title: "Partial Report" },
  //       { value: SCAN_STATUS.CALIBRATED, title: "Calibrated" },
  //       { value: SCAN_STATUS.COMPLETED, title: "Processing" },
  //       { value: SCAN_STATUS.BAD, title: "Error" },
  //     ]}
  //     onFilterChange={handleStatusFilterChange}
  //     placeholder="Status"
  //   ></ScanHistoryFilter>
  // )

  // // Scan field Select filter on scanner ID.
  // const scannerSelect = scanners.length > 1 && (
  //   <ScanHistoryFilter
  //     key="scannerFilter"
  //     options={scanners.map((scanner) => ({
  //       value: scanner.doc_id,
  //       title: scanner.name,
  //     }))}
  //     onFilterChange={handleScannerFilterChange}
  //     placeholder="Scanner"
  //   ></ScanHistoryFilter>
  // )

  // // Scan field Select filter on user.
  // const userSelect = orgUsers.length > 1 && (
  //   <ScanHistoryFilter
  //     key="userFilter"
  //     options={orgUsers.map((user) => ({
  //       value: user.doc_id,
  //       title: user.name,
  //     }))}
  //     onFilterChange={handleUserFilterChange}
  //     placeholder="User"
  //   ></ScanHistoryFilter>
  // )

  // // Scan field Select filter on date range.
  // const dateSelect = (
  //   <RangePicker
  //     className={css.scanDateRange}
  //     onChange={handleDateFilterChange}
  //     separator="to"
  //     allowClear={true}
  //     key="dateFilter"
  //   />
  // )

  const filterScans = (scans) => {
    let filtered = scans
    if (statusFilter !== undefined) {
      filtered = filtered.filter((scan) => scan.status === statusFilter)
    }
    if (userFilter !== undefined) {
      filtered = filtered.filter((scan) => scan.user_ref.id === userFilter)
    }
    if (scannerFilter !== undefined) {
      filtered = filtered.filter(
        (scan) => scan.scanner_ref.id === scannerFilter
      )
    }
    if (dateFilter.length > 1) {
      filtered = filtered.filter(
        (scan) =>
          scan.timestamp.seconds >= dateFilter[0] &&
          scan.timestamp.seconds <= dateFilter[1]
      )
    }
    const groupedByDate = groupByDate(filtered)

    return groupedByDate
  }

  const filteredScansByDate = scansFetched ? filterScans(state.scans) : []
  // const filters = [statusSelect, scannerSelect, userSelect, dateSelect]

  return (
    <div className="fadeIn">
      <div>
        {/* <Affix>
          <PageHeader title="" className={css.filters} extra={filters} />
        </Affix> */}
        {scansGrouped && Object.entries(filteredScansByDate).length > 0 ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreScans}
            hasMore={hasMore}
            loader={<div key="loading">Loading ...</div>}
          >
            {Object.entries(filteredScansByDate).map((date) => (
              <div key={date[0]} className="fadeIn">
                <Anchor
                  offsetTop={0}
                  targetOffset={128}
                  className={css.dateAnchor}
                >
                  <div className={css.innerDate}></div>
                  <Divider className={css.dateDivider}>
                    <div
                      className={css.dividerText}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      {date[0]}
                    </div>
                  </Divider>
                </Anchor>
                <Row className={css.layer1}>
                  {date[1].map((scan) => (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      key={scan.timestamp}
                    >
                      <ScanCard
                        key={scan.timestamp}
                        scan={scan}
                        mapStatusToVisualInfo={mapStatusToVisualInfo}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </InfiniteScroll>
        ) : scansGrouped ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Scans Found"
            className="centeredVH"
          />
        ) : (
          <Loading title="Scans" />
        )}
      </div>
    </div>
  )
}

ScanHistory.propTypes = {
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
}

export default ScanHistory
