import React from "react"
import {
  Timeline as AntdTimeline,
  Image,
  Menu,
  Dropdown,
  Popconfirm,
} from "antd"
import PropTypes from "prop-types"
import {
  ExclamationCircleOutlined,
  FileOutlined,
  FileImageOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from "@ant-design/icons"
import { toDayMonthTime, generateTimeString } from "../../../helpers/date"
import css from "./index.module.css"
import "./index.css"

const { Item } = AntdTimeline

const OverlayMenu = ({
  item,
  deleteItem,
  editItem,
  editable,
  setShowOnPrintout,
}) => {
  return (
    <Menu>
      {item.type === "note" && (
        <Menu.Item
          onClick={() => setShowOnPrintout(item.id, !item.showOnPrintout)}
        >
          <PrinterOutlined />
          {item.showOnPrintout ? "Hide" : "Show"} on Printout
        </Menu.Item>
      )}

      {editable && (
        <Menu.Item onClick={() => editItem(item.id)}>
          <div>
            <EditOutlined /> Edit
          </div>
        </Menu.Item>
      )}
      <Menu.Item danger className={css.menuItem}>
        <Popconfirm
          title="Are you sure you want to delete this item?"
          onConfirm={() => deleteItem(item.id)}
          okText="Yes"
          cancelText="No"
          className={css.popConfirm}
          icon={<ExclamationCircleOutlined />}
        >
          <div className={css.delete}>
            <DeleteOutlined /> Delete
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )
}

OverlayMenu.propTypes = {
  item: PropTypes.object,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
  editable: PropTypes.bool,
  onClick: PropTypes.func,
  setShowOnPrintout: PropTypes.func,
}

const addLabelsToItems = (items) => {
  // If items are on the same day but different times, only show date.
  // If items are same day same time, show no label for the subsequent item.
  // Only show author name if different from prior author.
  let date
  let time
  let name

  return items.map((item) => {
    let label = ""
    if (toDayMonthTime(item.timestamp.seconds) !== date) {
      label = toDayMonthTime(item.timestamp.seconds)
      date = toDayMonthTime(item.timestamp.seconds)
    }
    if (generateTimeString(item.timestamp.seconds) !== time) {
      label += " " + generateTimeString(item.timestamp.seconds)
      time = generateTimeString(item.timestamp.seconds)
    }
    const labelNode = (
      <div>
        <div>{label}</div>
        {name !== item.userName && (
          <div className={css.authorName}>{item.userName}</div>
        )}
      </div>
    )

    name = item.userName

    return { label: labelNode, ...item }
  })
}

const itemsByTypeAndDate = (
  items,
  deleteNote,
  editNote,
  deleteFile,
  deleteScratch,
  onItemEnter,
  onItemLeave,
  editing,
  setShowOnPrintout
) => {
  const labeledItems = addLabelsToItems(items)

  return labeledItems.map((item) => {
    switch (item.type) {
      case "note":
        return (
          <Item
            key={item.id}
            label={item.label}
            className={css.item}
            dot={
              editing === item.id ? (
                <EditOutlined style={{ fontSize: "16px", color: "red" }} />
              ) : item.showOnPrintout ? (
                <PrinterOutlined
                  style={{ fontSize: "16px", color: "#A288FF" }}
                />
              ) : (
                false
              )
            }
          >
            <div className={css.itemContainer}>
              <div className={css.itemContents}>Note: {item.note}</div>
              <Dropdown
                overlay={OverlayMenu({
                  item,
                  deleteItem: deleteNote,
                  editItem: editNote,
                  editable: true,
                  setShowOnPrintout: setShowOnPrintout,
                })}
                placement="bottomRight"
              >
                <div className={css.kebabMenu}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            </div>
          </Item>
        )
      case "scratch":
        return (
          <Item
            key={item.id}
            className={css.item}
            label={item.label}
            onMouseEnter={onItemEnter && (() => onItemEnter(item.id))}
            onMouseLeave={onItemLeave && (() => onItemLeave(item.id))}
            dot={
              <ExclamationCircleOutlined
                style={{ fontSize: "16px", color: "#a288ff" }}
              />
            }
          >
            <div className={css.itemContainer}>
              <div className={css.itemContents}>
                {item.id === "placeholder"
                  ? `No scratches on this surface.`
                  : `New scratch on the ${item.surface} element.`}
              </div>
              {item.id !== "placeholder" && (
                <Dropdown
                  overlay={OverlayMenu({
                    item,
                    deleteItem: deleteScratch,
                  })}
                  placement="bottomRight"
                >
                  <div className={css.kebabMenu}>
                    <MoreOutlined />
                  </div>
                </Dropdown>
              )}
            </div>
          </Item>
        )
      case "file":
        return item.fileType.includes("image") ? (
          <Item
            key={item.id}
            className={css.item}
            label={item.label}
            dot={
              <FileImageOutlined
                style={{ fontSize: "16px", color: "#a288ff" }}
              />
            }
          >
            <div className={css.itemContainer}>
              <div className={css.itemContents}>
                Image:{" "}
                <a
                  href={item.fileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </a>
                .
              </div>
              <Dropdown
                overlay={OverlayMenu({
                  item,
                  deleteItem: deleteFile,
                })}
                placement="bottomRight"
              >
                <div className={css.kebabMenu}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            </div>
            <Image width={100} src={item.fileLink} />
          </Item>
        ) : (
          <Item
            key={item.id}
            className={css.item}
            label={item.label}
            dot={
              <FileOutlined style={{ fontSize: "16px", color: "#a288ff" }} />
            }
          >
            <div className={css.itemContainer}>
              <div className={css.itemContents}>
                FIle:{" "}
                <a
                  href={item.fileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </a>
                .
              </div>
              <Dropdown
                overlay={OverlayMenu({
                  item,
                  deleteItem: deleteFile,
                })}
                placement="bottomRight"
              >
                <div className={css.kebabMenu}>
                  <MoreOutlined />
                </div>
              </Dropdown>
            </div>
          </Item>
        )
      default:
        return (
          <Item key={item.id} className={css.item} label={item.label}>
            <div className={css.itemContents}>{`Action: ${item.type}`}</div>
          </Item>
        )
    }
  })
}

const Timeline = ({
  items,
  pending,
  deleteNote,
  editing,
  editNote,
  deleteFile,
  deleteScratch,
  onItemEnter,
  onItemLeave,
  setShowOnPrintout,
}) => {
  const itemNodes = itemsByTypeAndDate(
    items,
    deleteNote,
    editNote,
    deleteFile,
    deleteScratch,
    onItemEnter,
    onItemLeave,
    editing,
    setShowOnPrintout
  )

  return (
    <div className={css.container}>
      {items && (
        <div className={css.itemsContainer} id="tour-step-6">
          <AntdTimeline pending={pending} mode="left">
            {itemNodes}
          </AntdTimeline>
        </div>
      )}
    </div>
  )
}

Timeline.propTypes = {
  /** @type{object[]} Array of Timeline entries. */
  items: PropTypes.array,
  /** @type{Boolean} Antd Timeline pending property. */
  pending: PropTypes.bool,
  /** @type{Boolean|string} Whether Timeline is being edited. */
  editing: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** @type{Function} Delete Note from Timeline. */
  deleteNote: PropTypes.func,
  /** @type{Function} Edit Timeline Note. */
  editNote: PropTypes.func,
  /** @type{Function} Delete File from Timeline. */
  deleteFile: PropTypes.func,
  /** @type{Function} Delete Scratch from Timeline. */
  deleteScratch: PropTypes.func,
  /** @type{function(string)} Highlight given scratch in scratch sheet. */
  onItemEnter: PropTypes.func,
  /** @type{function(string)} Un-highlight given scratch in scratch sheet. */
  onItemLeave: PropTypes.func,
  /** @type{function(Boolean)} Set item to be shown on printout from parent
    component useState(). */
  setShowOnPrintout: PropTypes.func,
}

export default Timeline
