import React, { useEffect, useState } from "react"
import css from "./SandboxNotice.module.css"
import { Affix, Alert, Button, Modal } from "antd"
import { useGlobalState } from "../../state"
import { QuestionOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import tour, { elementRender } from "./tour"
import SalesModal from "./SalesModal"

const SandboxNotice = () => {
  const [state] = useGlobalState()
  const [modalVisible, setModalVisible] = useState(false)

  const handleModalCancel = () => setModalVisible(false)

  const handleStartTutorial = async () => {
    handleModalCancel()
    await elementRender("tour-step-0").then(() => {
      const stepZero = document.getElementById("tour-step-0")
      stepZero.classList.add("tourHover")
      tour.start()
    })
  }

  const sandboxModal = (
    <Modal
      visible={modalVisible}
      onCancel={handleModalCancel}
      maskStyle={{ backdropFilter: "blur(8px)" }}
      title="Welcome to the Tracis Sandbox"
      zIndex={101}
      footer={
        <div>
          <Button type="primary" onClick={handleModalCancel} ghost>
            Got it
          </Button>
          <Button type="primary">
            <Link to="/scan_history" onClick={handleStartTutorial}>
              Start tutorial
            </Link>
          </Button>
        </div>
      }
    >
      Feel free to click on anything and save any data in this sandbox. It will
      be reset every day at 5 A.M. GMT.
      <br />
      <br />
      <ul>
        <li>
          Check out the rear element of{" "}
          <Link
            to="/scan_history?lens=tPsrt08RLsXF2hZu1KuC&date_a=1600858648&surface=rear%20(0000)"
            onClick={handleModalCancel}
          >
            this 75mm ARRI Master Prime
          </Link>{" "}
          from the scan history page.
        </li>
        <li>
          Check out{" "}
          <Link
            to="/inventory?lens=VXBmV06YXHoJtfMNRx3t&date_a=1600791715&surface=front%20(1000)"
            onClick={handleModalCancel}
          >
            the scratch on this Elite Digital 35mm
          </Link>{" "}
          and its associated timeline and scratch sheet markings.
        </li>
        <li>
          See if you can start your own scan of the 75mm ARRI Master Prime using
          the{" "}
          <Link to="/scan" onClick={handleModalCancel}>
            scan wizard
          </Link>
          .
        </li>
      </ul>
    </Modal>
  )

  useEffect(() => {
    if (!state.sandbox) return
    setModalVisible(true)
  }, [state.sandbox])

  const sandboxBannerMessage = state.sandbox && (
    <div>
      Welcome to the Tracis sandbox. Data will reset on Sunday at 5 A.M. GMT.
      <Button
        type="link"
        icon={<QuestionOutlined style={{ color: "#A288FF" }} />}
        onClick={() => setModalVisible(true)}
      >
        More Info
      </Button>
    </div>
  )

  return (
    <div>
      {sandboxModal}
      <SalesModal />
      <Affix offsetBottom={0} style={{ position: "fixed", bottom: 0 }}>
        <Alert
          type="info"
          message={sandboxBannerMessage}
          banner
          className={css.sandboxBanner}
        />
      </Affix>
    </div>
  )
}

export default SandboxNotice
