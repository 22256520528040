import React from "react"
import settingsCss from "./index.module.css"
import PropTypes from "prop-types"
import { Button, Typography, Select, Space } from "antd"
import { useGlobalState } from "../../state"

const { Option } = Select
const { Title } = Typography

const SessionDetails = ({ orgsData, user, signOutAndRedirect }) => {
  const [state, dispatch] = useGlobalState()

  const handleUserOrgChange = (value) => {
    dispatch({ type: "SET_USER_ORG_ID", userOrgId: value })
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Title level={4} className={settingsCss.title}>
        Session
      </Title>
      <Space
        size={70}
        align="center"
        style={{ width: "inherit", justifyContent: "center" }}
      >
        <div>Signed in as {user.email}</div>
        {orgsData.length > 1 && (
          <Select
            value={state.userOrgId}
            onChange={handleUserOrgChange}
            style={{ width: "120px" }}
          >
            {orgsData.map((org) => (
              <Option key={org._id} value={org._id}>
                {org.name}
              </Option>
            ))}
          </Select>
        )}
        <Button type="primary" onClick={signOutAndRedirect}>
          Sign Out
        </Button>
      </Space>
    </div>
  )
}

SessionDetails.propTypes = {
  /** @type{{_id: string, name: string}[]} Array of user's org objects. */
  orgsData: PropTypes.array,
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
  /** @type{Function} Call Firebase signOut method and redirect to /signin. 
    This is a drilled prop. */
  signOutAndRedirect: PropTypes.func,
}

export default SessionDetails
