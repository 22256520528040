import { captureException } from "@sentry/react"
import { COLLECTION } from "../constants"
import { getDb, getImagesFromCloudStorage } from "./helpers"

const sharingLinks = {
  createSharingLink: async (
    leftScan,
    rightScan,
    surface,
    orgId,
    userId,
    serialNumber
  ) => {
    const db = await getDb()
    const left_scan_ref = db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANS)
      .doc(leftScan._id)
    let right_scan_ref = null
    if (rightScan !== undefined) {
      right_scan_ref = db
        .collection(COLLECTION.ORGANIZATIONS)
        .doc(orgId)
        .collection(COLLECTION.SCANS)
        .doc(rightScan._id)
    }
    const org_ref = db.collection(COLLECTION.ORGANIZATIONS).doc(orgId)
    const user_ref = db.collection(COLLECTION.USERS).doc(userId)
    let fieldUpdates = {
      org_ref: org_ref,
      user_ref: user_ref,
      left_scan_ref: left_scan_ref,
      right_scan_ref: right_scan_ref,
      lens_type_ref: leftScan.lens_type_ref,
      lens_ref: leftScan.lens_ref,
      serial_number: serialNumber,
      surface: surface,
      ready: false,
    }
    const newSharingLink = await db
      .collection(COLLECTION.SHARING_LINKS)
      .add(fieldUpdates)
    return newSharingLink.id
  },
  onSharingLinkCreate: async (sharingLinkId, onChange) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.SHARING_LINKS)
      .doc(sharingLinkId)
      .onSnapshot((snapshot) =>
        onChange({ ...snapshot.data(), id: snapshot.id })
      )
  },
  getSharingLinksByLens: async (lens_ref) => {
    try {
      const db = await getDb()
      const snapshot = await db
        .collection(COLLECTION.SHARING_LINKS)
        .where("lens_ref", "==", lens_ref)
        .where("org_ref", "==", lens_ref.parent.parent)
        .get()
      let sharingLinks = snapshot.docs.map((s) => {
        return { id: s.id, ...s.data() }
      })
      let leftScanPromises = []
      let rightScanPromises = []
      sharingLinks.forEach((sl) => {
        leftScanPromises.push(sl.left_scan_ref.get())
        rightScanPromises.push(
          sl.right_scan_ref ? sl.right_scan_ref.get() : null
        )
      })
      leftScanPromises = await Promise.all(leftScanPromises)
      rightScanPromises = await Promise.all(rightScanPromises)

      let linksData = []
      for (var i = 0; i < sharingLinks.length; i++) {
        let sl = sharingLinks[i]
        sl.leftScan = leftScanPromises[i].data()
        sl.rightScan = rightScanPromises[i] ? rightScanPromises[i].data() : null
        linksData.push(sl)
      }
      linksData.sort(
        (a, b) => b.leftScan.timestamp.seconds - a.leftScan.timestamp.seconds
      )
      return linksData
    } catch (err) {
      console.error(
        `Error getting sharing links for lens ${lens_ref.id}: ${err}`
      )
      captureException(err)
    }
  },
  deleteSharingLink: async (linkId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.SHARING_LINKS)
      .doc(linkId)
      .delete()
      .then(console.log(`Successfully deleted SharingLink ${linkId}.`))
      .catch((error) => {
        console.error(`Error deleting SharingLink ${linkId}: ${error}.`)
        captureException(error)
      })
  },
  getSharingLink: async (sharingLinkId) => {
    const db = await getDb()
    let sharingLink = db.collection(COLLECTION.SHARING_LINKS).doc(sharingLinkId)
    const leftScan = await sharingLink
      .collection(COLLECTION.SHARED_DOCS)
      .doc("leftScan")
      .get()
    let sharingLinkData = await sharingLink.get()
    sharingLinkData = sharingLinkData.data()
    if (sharingLinkData === undefined) return undefined
    let rightScan = undefined
    let rightScanData = undefined
    if (sharingLinkData.right_scan_ref !== null) {
      rightScan = await sharingLink
        .collection(COLLECTION.SHARED_DOCS)
        .doc("rightScan")
        .get()
      rightScanData = rightScan.data()
      rightScanData = await getImagesFromCloudStorage([rightScanData])
      rightScanData = rightScanData[0]
    }

    const lensType = await sharingLink
      .collection(COLLECTION.SHARED_DOCS)
      .doc("lensType")
      .get()

    let leftScanData = leftScan.data()
    const lensTypeData = lensType.data()
    const serialNumber = sharingLinkData.serial_number

    leftScanData = await getImagesFromCloudStorage([leftScanData])
    leftScanData = leftScanData[0]
    const data = {
      leftScan: leftScanData,
      rightScan: rightScanData,
      sharingLink: sharingLinkData,
      lensType: lensTypeData,
      serialNumber: serialNumber,
    }
    return data
  },
}

export default sharingLinks
