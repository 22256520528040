import { generateIndex } from "../state/firebase/helpers"
const TrieSearch = require("trie-search")

// Create a search trie of lens keywords for prefix search. This is implemented
// by splitting up individual make/model words and assigning them to numbered
// keys. These keys can then be searched for in the implementation of the
// trie-search npm package.
// (e.g. { make: "Dog Schidt Optiks", ... } ->
//    { _make0: "dog", _term0: "schidt", _make2: "optiks", ... })
export const generateTrie = (lenses, lensTypesTree = false) => {
  if (lenses[0] === undefined) return
  lenses = lenses.filter(
    (l) =>
      l.make !== undefined &&
      l.model !== undefined &&
      l.focal_length_description !== undefined
  )

  if (!lensTypesTree) {
    lenses = lenses.filter((l) => l.serial_number !== undefined)
  }

  let maxTermCount = 0 // Counter for _termN.

  // Keys in lens object on which trie-search builds/searches.
  let keys = [
    "focal_length_with_suffix",
    "focal_length_description",
    "_term0",
    "serial_number",
    "barcode",
  ]

  lenses.forEach((lens) => {
    const terms = generateLensKeywords(lens)
    // Associate keywords with lens using arbitrary _termN keys.
    terms.forEach((term, i) => {
      lens[`_term${i}`] = term
      if (i > maxTermCount) {
        maxTermCount = i
        keys.push(`_term${i}`)
      }
    })

    // Ensure search responds to "14" or "14mm" the same way.
    lens.focal_length_with_suffix = `${lens.focal_length_description}mm`
    // The `flatTitle` needs to include every part of the lens description
    // that the user can search for, since in `findLenses()` below we filter
    // out any search terms that _don't_ appear in the `flatTitle`.
    lens.flatTitle = `${lens.make} ${lens.model} ${lens.focal_length_description}mm`
    if (lens.serial_number !== undefined) {
      lens.flatTitle += `• ${lens.serial_number}`
    }
    if (lens.barcode !== undefined) {
      lens.flatTitle += `• ${lens.barcode}`
    }
  })
  // Lens key "uniqueIdentifier" is generated in helpers::aliasLenses() and is its
  // true unique id, since aliased lenses have the same type_id, serial_number, etc.
  const ts = new TrieSearch(keys, { idFieldOrFunction: "uniqueIdentifier" })
  ts.addAll(lenses)
  return ts
}

// Un-nest lens.lenses into an array of lens instances one level deep.
export const generateLensInstances = (lenses, onlyLensesWithSerialNumbers) => {
  let lensInstances = []
  lenses.forEach((lens, i) => {
    if (lens.lenses !== undefined) {
      lens.lenses.forEach((sn) =>
        lensInstances.push({
          ...lens,
          serial_number: sn.serial_number,
          barcode: sn.barcode,
          lens_id: sn.lens_id,
          uniqueIdentifier: generateIndex(),
        })
      )
    }
    if (!onlyLensesWithSerialNumbers) {
      lensInstances.push({ ...lens, uniqueIdentifier: generateIndex() })
    }
  })
  return lensInstances
}

// Get lists of sanitized make, model parts for lens.
export const generateLensKeywords = (lens) => {
  let terms = []

  // Split each lens's make its individual parts.
  terms = [
    ...terms,
    ...lens.make
      .toLowerCase()
      // Split by spaces and slashes (e.g. Arriflex/Zeiss, IB/E Optics)
      .split(/[/\s]+/g)
      .map((term) => removeLeadingTrailingPuctuation(term)),
  ]

  // Split each lens's model its individual parts.
  terms = [
    ...terms,
    ...lens.model
      .toLowerCase()
      .split(" ")
      .map((term) => removeLeadingTrailingPuctuation(term)),
  ]

  return terms
}

const removeLeadingTrailingPuctuation = (str) => {
  if (str[-1] === ",") {
    str = str.slice(0, -1)
  }
  return str
}

// Search trie.
export const findLenses = (trie, searchInput) => {
  return new Promise((resolve, _reject) => {
    let input = searchInput.split(" ")
    input = input.filter((str) => str.length !== 0)

    // Filter out lens names that do not include all search terms.
    const filtered = trie.get(input).filter((l) => {
      for (const i of input) {
        if (!l.flatTitle.toLowerCase().includes(i.toLowerCase())) {
          return false
        }
      }

      return true
    })

    resolve(filtered)
  })
}
