import React, { FC, useEffect, useState } from "react"
import { Form, Input } from "antd"
import { useGlobalState } from "../../state"
import lensesApi from "../../state/firebase/lenses"
import {
  LensDocument,
  Validation,
  BARCODE_EXISTS,
  SN_EXISTS,
  SUCCESS,
  ERROR,
  NULL,
  Lens,
} from "../common/types"

const successValidation: Validation = {
  validateStatus: SUCCESS as SUCCESS,
  errorMsg: NULL as NULL,
}

interface Props {
  lens: LensDocument | undefined
  fieldValues: Lens | undefined
}

const LensInstanceFields: FC<Props> = ({ lens, fieldValues }) => {
  const [state] = useGlobalState()
  const [serialNumbers, setSerialNumbers] = useState<Set<string>>(new Set())
  const [barcodes, setBarcodes] = useState<Set<string>>(new Set())
  const [matchingBarcode, setMatchingBarcode] = useState<Validation>(
    successValidation
  )
  const [matchingSN, setMatchingSN] = useState<Validation>(successValidation)

  // Create initial Sets of existing `serial_number`s and `barcode`s that do
  // not including the lens passed in Props.
  useEffect(() => {
    lensesApi.getLenses(state.userOrgId).then((lenses: Array<LensDocument>) => {
      setSerialNumbers(
        new Set(
          lenses
            .filter(
              (l) =>
                lens !== undefined &&
                l.lens_id !== lens.lens_id &&
                l.serial_number !== null &&
                l.serial_number !== undefined
            )
            .map((lens: LensDocument) => lens.serial_number.toLowerCase())
        )
      )
      setBarcodes(
        new Set(
          lenses
            .filter(
              (l) =>
                lens !== undefined &&
                l.lens_id !== lens.lens_id &&
                l.barcode !== null &&
                l.barcode !== undefined
            )
            .map((lens: LensDocument) => lens.barcode!.toLowerCase())
        )
      )
    })
  }, [])

  // Check for existing serial_number and barcode upon fields change.
  useEffect(() => {
    if (fieldValues === undefined) return
    setMatchingSN(
      checkMatchingSN(
        serialNumbers.has(fieldValues.serial_number.toLowerCase())
      )
    )
    setMatchingBarcode(
      checkMatchingBarcode(
        fieldValues.barcode !== undefined &&
          fieldValues.barcode !== "" &&
          barcodes.has(fieldValues.barcode.toLowerCase())
      )
    )
  }, [fieldValues])

  // Custom validation for `serial_number` and `barcode` fields per antd
  // documentation. These show the warning messages but still allow the form to
  // be submitted, whereas antd's inbuilt `async-validator` does not allow the
  // form to be submitted if there are error/warning messages.
  const checkMatchingSN = (matches: boolean) => {
    return matches
      ? {
          validateStatus: ERROR as ERROR,
          errorMsg: SN_EXISTS as SN_EXISTS,
        }
      : successValidation
  }
  const checkMatchingBarcode = (matches: boolean) => {
    return matches
      ? {
          validateStatus: ERROR as ERROR,
          errorMsg: BARCODE_EXISTS as BARCODE_EXISTS,
        }
      : successValidation
  }

  return (
    <div>
      <Form.Item
        name="serial_number"
        label={<span>Serial Number</span>}
        rules={[{ required: true, message: "Serial number is required." }]}
        validateStatus={matchingSN.validateStatus}
        help={matchingSN.errorMsg}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="barcode"
        label={<span>Barcode</span>}
        validateStatus={matchingBarcode.validateStatus}
        help={matchingBarcode.errorMsg}
      >
        <Input />
      </Form.Item>
    </div>
  )
}

export default LensInstanceFields
