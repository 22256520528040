import React from "react"
import css from "./Default.module.css"

const Default = () => {
  return (
    <div className={css.centered}>
      <img src="camera-lens.png" alt="camera lens" />
      <br />
      <h2>Select a Lens</h2>
    </div>
  )
}

export default Default
