const generateSurfacesSet = (leftScan, rightScan) => {
  let allSurfaces = []
  if (leftScan === undefined && rightScan === undefined) return allSurfaces
  if (leftScan !== undefined) {
    allSurfaces = allSurfaces.concat(Object.keys(leftScan.surfaces))
  }
  if (rightScan !== undefined) {
    allSurfaces = allSurfaces.concat(Object.keys(rightScan.surfaces))
  }

  allSurfaces = [...new Set(allSurfaces)]
  allSurfaces = allSurfaces.sort((s1, s2) => {
    // Surface is 'Unknown' or doesn't contain a number -- sort it to the bottom.
    if (s1.match(/\d+/) === null) return -1
    if (s2.match(/\d+/) === null) return -1
    // Parse the surface name for its digits and sort descending.
    return parseInt(s1.match(/\d+/)[0]) > parseInt(s2.match(/\d+/)[0]) ? -1 : 1
  })
  return allSurfaces
}
export default generateSurfacesSet
