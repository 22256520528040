import { COLLECTION } from "../constants"
import { getDb, timestamp } from "./helpers"

const notes = {
  addNote: async (orgId, lensId, note, uid, displayName, showOnPrintout) => {
    const db = await getDb()
    const noteRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.NOTES)
      .add({
        note,
        timestamp: timestamp(),
        userId: uid,
        userName: displayName,
        showOnPrintout,
      })

    const noteObject = await noteRef.get()
    return { id: noteObject.id }
  },
  getNotes: async (orgId, lensId) => {
    const db = await getDb()
    const lensRef = db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)

    const snapshot = await lensRef
      .collection(COLLECTION.NOTES)
      .orderBy("timestamp")
      .get()

    const lens = await lensRef.get()
    const lensData = lens.data()

    const notes = []
    snapshot.docs.forEach((doc) => {
      notes.push({
        id: doc.id,
        ...doc.data(),
        lens: { ...lensData, id: lens.id },
      })
    })

    return notes
  },
  onNotes: async (orgId, lensId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.NOTES)
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const notes = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        onChange(notes)
      })
  },
  deleteNote: async (orgId, lensId, noteId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.NOTES)
      .doc(noteId)
      .delete()
      .then(() => console.log(`Note ${noteId} deleted.`))
  },
  editNote: async (orgId, lensId, noteId, note) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.NOTES)
      .doc(noteId)
      .update(note)
      .then(() => {
        return new Promise((resolve, reject) => reject("Riley's promise error"))
      })
      .then(() => console.log(`Note ${noteId} updated successfully`))
  },
}

export default notes
