const MINUTE = 60,
  HOUR = 3600,
  DAY = 86400,
  WEEK = 604800,
  MONTH = 18144000,
  YEAR = 31556952

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

// Create "<day> <month>" date string (e.g. "20 May", "1 December")
export const toMonthDayTime = (seconds) => {
  const jsDate = new Date(seconds * 1000)
  const month = months[jsDate.getMonth()]
  const day = jsDate.getDate()
  return `${day} ${month}`
}

// Create <month_truncated> <day> date string (e.g. "Sep. 28")
export const toDayMonthTime = (seconds) => {
  const jsDate = new Date(seconds * 1000)
  let month = months[jsDate.getMonth()]
  const day = jsDate.getDate()
  if (month.length > 3) {
    month = month.substring(0, 3) + "."
  }
  return `${month} ${day}`
}

// Create date string in format "MM/DD/YY, HH:MM AM/PM".
// Note: May be "MM/DD/YY" and/or 24-hr time depending on Locale format.
export const generateDateTimeString = (unixTimestamp) => {
  return new Date(unixTimestamp * 1000).toLocaleString([], {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
}

export const year = (seconds) => {
  const jsDate = new Date(seconds * 1000)
  let year = jsDate.getFullYear()

  return `${year}`
}

export const generateShortDateTimeString = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000)
  const thisYear = new Date().getFullYear()
  const dateString =
    date.getFullYear() === thisYear
      ? date.toLocaleString([], {
          day: "numeric",
          month: "short",
        })
      : date.toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
  const timeString = date.toLocaleString([], {
    hour: "numeric",
    minute: "numeric",
  })
  return `${dateString} - ${timeString}`
}

// Create date string in format "MM/DD/YY".
// Note: May be "MM/DD/YY" depending on Locale format.
export const generateDateString = (unixTimestamp) => {
  return new Date(unixTimestamp * 1000).toLocaleDateString([], {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

// Parse time into largest time unit (seconds, minutes, etc.). Helper for generateFriendlyDate.
const timeSince = (timestamp) => {
  // Timestamp must be in UTC format.
  const now = new Date()

  const secondsPast = (now.getTime() - timestamp) / 1000
  if (secondsPast < MINUTE) return [parseInt(secondsPast), "second"]
  if (secondsPast < HOUR) return [parseInt(secondsPast / MINUTE), "minute"]
  if (secondsPast < DAY) return [parseInt(secondsPast / HOUR), "hour"]
  if (secondsPast < WEEK) return [parseInt(secondsPast / DAY), "day"]
  if (secondsPast < MONTH) return [parseInt(secondsPast / WEEK), "week"]
  if (secondsPast <= YEAR) return [parseInt(secondsPast / MONTH), "month"]
  if (secondsPast > YEAR) return [parseInt(secondsPast / YEAR), "year"]
}

// Display date as "<amount> <time_units> ago" (e.g. "5 minutes ago", "1 month ago")
export const generateFriendlyDate = (seconds) => {
  const date = new Date(seconds * 1000)
  const amtUnit = timeSince(date)
  let amt = amtUnit[0]
  let units = amtUnit[1]
  if (amt > 1) units += "s"
  return `${amt} ${units} ago`
}

// Display time in 12-hour or 24-hour depending on locale ("13:48", "1:48 PM")
export const generateTimeString = (seconds) => {
  return new Date(seconds * 1000).toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  })
}

// Display "Weekday, Month Day, Year" if > 2 days ago, "yesterday" if > 1 day
// ago, or nothing if <= 1 day ago.
export const notificationDate = (seconds) => {
  const now = Date.now()
  const diff = (now - 1000 * seconds) / 1000
  if (diff > DAY * 2) {
    return ` on ${generateDateString(seconds)}`
  } else if (diff > DAY) {
    return " from yesterday"
  } else {
    return ""
  }
}
