// Generate title string for lens make/model/focal_length. If a user has chosen
// an alias of a lens, it is stored in the scan as "lens_make", "lens_model" and
// given preference in display. Otherwise the LensType make/model are displayed.
export const lensTitle = (scan) => {
  return `${scan.lens_make || scan.lens.make} ${
    scan.lens_model || scan.lens.model
  } ${scan.lens.focal_length_description}mm`
}

export const missingLensTypeFields = (lens) => {
  return (
    lens.image_diameter === null ||
    lens.image_diameter === 0 ||
    lens.tstop === null ||
    lens.make === null ||
    lens.model === null ||
    lens.length === null ||
    lens.focal_length_mm === null
  )
}

// Currently do not support low focal lengths or anamorphics.
export const unsupported = (lens) => {
  if (lens.focal_length_mm < 20) return true
  if (lens.model && lens.model.toLowerCase().includes("anamorphic")) return true
  if (lens.type_id === "W2TwQ7lWcKZiaAKOboE8") return true
  if (lens.anamorphic) return true
  return false
}
