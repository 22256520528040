import React, { useState } from "react"
import PropTypes from "prop-types"
import settingsCss from "./index.module.css"
import { firestore as db } from "../../state/firebase"
import { Radio, Typography, Form, Input, Button, Divider } from "antd"

const { Title } = Typography
const AddOrganization = ({ user }) => {
  const [newOrg, setNewOrg] = useState(true)
  const [newOrgForm] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const onNewOrg = () => {
    const values = newOrgForm.getFieldsValue()
    db.createNewOrg(user.uid, values.orgName)
  }

  const toggleNewOrg = (e) => {
    setNewOrg(e.target.value)
  }

  return (
    <div className={settingsCss.fullWidth}>
      <Title level={4} className={settingsCss.title}>
        Register with Organization
      </Title>
      <Radio.Group name="newOrg" value={newOrg} onChange={toggleNewOrg}>
        <Radio value={true}>New Organization</Radio>
        <Radio value={false}>Existing Organization</Radio>
      </Radio.Group>
      <Divider />
      <Form
        {...layout}
        onFinish={onNewOrg}
        form={newOrgForm}
        style={{ width: "100%" }}
      >
        <Form.Item
          label="Organization Name"
          name="orgName"
          rules={[
            {
              required: true,
              message: "Organization name is required. ",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Acme Rentals" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" ghost block>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

AddOrganization.propTypes = {
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
}

export default AddOrganization
