import React, { FC, useState } from "react"
import { Button, Form, Input, Row, Col } from "antd"
import { useGlobalState } from "../../state"
import lensesApi from "../../state/firebase/lenses"
import LensInstanceFields from "./LensInstanceFields"
import css from "./index.module.css"
import { LensInstance, Lens } from "../common/types"

const Details: FC = () => {
  const [state, dispatch] = useGlobalState()
  const [changed, setChanged] = useState<Boolean>(false)
  const [fieldValues, setFieldValues] = useState<Lens>()
  const [form] = Form.useForm()

  const lens = state.inventoryLens
  lens.focal_length_description =
    lens.focal_length_description || lens.focal_length_mm || lens.focal_length

  const handleFieldsChange = () => {
    setChanged(true)
    setFieldValues(form.getFieldsValue())
  }

  const handleSubmit = (values: Lens) => {
    const updateValues: LensInstance = {
      serial_number: values.serial_number,
      barcode: values.barcode,
    }

    lensesApi
      .updateLens(state.userOrgId, state.inventoryLens.lens_id, updateValues)
      .then(() => setChanged(false))
      .then(() => {
        // TODO: Remove setTimeout. This setTimeout is the result of a rece condition
        // somewhere in our code.
        // GLOBAL TODO: Figure out what's going on with local component state and useEffect callbacks
        // that would cause our app to have this race condition. This TODO may require the refactoring
        // of useEffect hooks and local state on components up the render tree.
        setTimeout(
          () =>
            dispatch({
              type: "SET_INVENTORY_LENS",
              lens: { ...state.inventoryLens, ...updateValues },
            }),
          3000
        )
      })
  }

  return (
    <div className={css.container}>
      <div className={css.form}>
        <Form
          form={form}
          name="details"
          scrollToFirstError
          layout="vertical"
          initialValues={lens}
          onFieldsChange={handleFieldsChange}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="make" label={<span>Manufacturer</span>}>
                <Input disabled />
              </Form.Item>
              <LensInstanceFields lens={lens} fieldValues={fieldValues} />
              <Form.Item
                name="focal_length_description"
                label={<span>Focal Length</span>}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item name="length" label={<span>Physical Length</span>}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="image_diameter"
                label={<span>Image Diameter</span>}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="model" label={<span>Model</span>}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="housing_make" label={<span>Housing</span>}>
                <Input disabled={true} />
              </Form.Item>
              <Form.Item name="fstop" label={<span>F-stop</span>}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="tstop" label={<span>T-stop</span>}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="mount" label={<span>Mount Type</span>}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="entrance_pupil"
                label={<span>Entrance Pupil</span>}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button disabled={!changed} htmlType="submit">
              Save Changes
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default Details
