import React, { useEffect, useState } from "react"
import Input from "../common/Input"
import CommonTimeline from "../common/Timeline"
import scratchesApi from "../../state/firebase/scratches"
import notesApi from "../../state/firebase/notes"
import filesApi from "../../state/firebase/files"
import { sortTimestamp } from "../../state/firebase/helpers"
import { useGlobalState } from "../../state"
import css from "./index.module.css"
import "./index.css"

const Timeline = () => {
  const [state] = useGlobalState()
  const [notes, setNotes] = useState([])
  const [files, setFiles] = useState([])
  const [scratches, setScratches] = useState([])
  const [note, setNote] = useState()
  const [file, setFile] = useState()
  const [image, setImage] = useState()
  const [pending, setPending] = useState(false)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const unsubNotes = notesApi.onNotes(
      state.userOrgId,
      state.inventoryLens.lens_id,
      (notes) => {
        const labeledNotes = notes.map((note) => ({ type: "note", ...note }))
        setNotes(labeledNotes)
      }
    )

    const unsubScratches = scratchesApi.onScratches(
      state.userOrgId,
      state.inventoryLens.lens_id,
      (scratches) => {
        const labeledScratches = scratches.map((scratch) => ({
          type: "scratch",
          ...scratch,
        }))
        setScratches(labeledScratches)
      }
    )

    const unsubFiles = filesApi.onFiles(
      state.userOrgId,
      state.inventoryLens.lens_id,
      (files) => {
        const labeledFiles = files.map((file) => ({
          ...file,
          fileType: file.type,
          type: "file",
        }))
        setFiles(labeledFiles)
      }
    )

    return () => {
      unsubNotes.then((unsub) => unsub())
      unsubScratches.then((unsub) => unsub())
      unsubFiles.then((unsub) => unsub())
    }
  }, [])

  const addNote = (showOnPrintout) => {
    setNote()
    setPending(true)
    notesApi
      .addNote(
        state.userOrgId,
        state.inventoryLens.lens_id,
        note,
        state.uid,
        state.userName,
        showOnPrintout
      )
      .then(() => setPending(false))
  }

  const addFile = (file) => {
    setFile()
    setImage()
    setPending(true)
    filesApi
      .addFile(
        state.userOrgId,
        state.inventoryLens.lens_id,
        file,
        state.uid,
        state.userName
      )
      .then(() => setPending(false))
  }

  const deleteNote = (noteId) => {
    setPending(true)
    notesApi
      .deleteNote(state.userOrgId, state.inventoryLens.lens_id, noteId)
      .then(() => setPending(false))
  }

  const deleteFile = (fileId) => {
    setPending(true)
    filesApi
      .deleteFile(state.userOrgId, state.inventoryLens.lens_id, fileId)
      .then(() => setPending(false))
  }

  const deleteScratch = (scratchId) => {
    setPending(true)
    scratchesApi
      .deleteScratch(state.userOrgId, state.inventoryLens.lens_id, scratchId)
      .then(() => setPending(false))
  }

  const editNote = (noteId) => {
    setEditing(noteId)
    const note = notes.find((note) => note.id === noteId)
    setNote(note.note)
  }

  const saveEditNote = (noteId) => {
    setPending(true)
    notesApi
      .editNote(state.userOrgId, state.inventoryLens.lens_id, noteId, { note })
      .then(() => setPending(false))
      .then(() => setEditing(false))
      .then(() => setNote(""))
  }

  const setShowOnPrintout = (noteId, show) => {
    notesApi.editNote(state.userOrgId, state.inventoryLens.lens_id, noteId, {
      showOnPrintout: show,
    })
  }

  const cancelEditNote = () => {
    setNote("")
    setEditing(false)
  }

  const insertPlaceholderNote = () => {
    if ([...notes, ...scratches, ...files].length === 0) {
      setNotes([
        {
          id: "placeholder",
          type: "note",
          note: "No notes yet for this lens. Add one!",
          timestamp: { seconds: new Date() / 1000 },
        },
      ])
    }
  }

  insertPlaceholderNote()

  return (
    <div className={css.container}>
      <div className={css.timeline}>
        <Input
          onChange={(e) => setNote(e.target.value)}
          addNote={addNote}
          value={note}
          image={image}
          file={file}
          editing={editing}
          saveEditNote={saveEditNote}
          setImage={setImage}
          setFile={setFile}
          onAddFile={addFile}
          cancelEditNote={cancelEditNote}
          id="tour-step-7"
        />
        <div className={css.items}>
          <CommonTimeline
            items={sortTimestamp([...notes, ...scratches, ...files])}
            editing={editing}
            pending={pending}
            deleteNote={deleteNote}
            editNote={editNote}
            deleteFile={deleteFile}
            deleteScratch={deleteScratch}
            setShowOnPrintout={setShowOnPrintout}
          />
        </div>
      </div>
    </div>
  )
}

export default Timeline
