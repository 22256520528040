// Modify window history in scan_history by pushing scan timestamp(s), lens id,
// and surface name to url search params.
export const pushScanToHistory = (leftScan, surface, rightScan = undefined) => {
  if (leftScan === undefined || surface === undefined) return
  const lensId = leftScan.lens_ref.id
  const dateA = leftScan.timestamp.seconds
  let dateB
  if (rightScan !== undefined) {
    dateB = rightScan.timestamp.seconds

    window.history.pushState(
      {
        lens: lensId,
        date_a: dateA,
        date_b: dateB,
        surface: surface,
      },
      "",
      encodeURI(
        `?lens=${lensId}&date_a=${dateA}&date_b=${dateB}surface=${surface}`
      )
    )
  } else {
    window.history.pushState(
      {
        lens: lensId,
        date_a: dateA,
        surface: surface,
      },
      "TITLE",
      encodeURI(`?lens=${lensId}&date_a=${dateA}&surface=${surface}`)
    )
  }
}

// Modify window history in /inventory by pushing lens id to url search params.
export const pushLensToHistory = (lens) => {
  const lensId = lens.lens_id
  window.history.pushState(
    {
      lens: lensId,
    },
    ``,
    encodeURI(`?lens=${lensId}`)
  )
}

// Reset window history to base path upon canceling out of modal.
export const resetHistory = () => {
  window.history.pushState({}, "TITLE", window.location.pathname)
}
