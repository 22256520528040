import React from "react"
import { FilterOutlined, WarningOutlined } from "@ant-design/icons"
import { unsupported } from "./lens"

// Categorize image_diameter float into English frame size.
const friendlyImageDiameter = (image_diameter) => {
  if (image_diameter >= 43) return "Full Frame"
  if (image_diameter >= 30) return "Super 35"
  if (image_diameter >= 15) return "Super 16"
  return ""
}

// Add leaf to lens tree.
const addLeaf = (lens, key, field) => {
  return {
    title: lens.title,
    make: lens.make,
    model: lens.model,
    focal_length_mm: lens.focal_length_mm,
    focal_length_description: lens.focal_length_description,
    calibrated_front_rear: lens.calibrated_front_rear,
    fstop: lens.fstop,
    tstop: lens.tstop,
    length: lens.length,
    image_diameter: lens.image_diameter,
    supported: !unsupported(lens),
    type_id: lens.type_id,
    lenses: lens.lenses,
    isLeaf: true,
    value: key,
    key: key,
    field: field,
    className: "css.treeNode",
    icon: unsupported(lens) ? (
      <WarningOutlined />
    ) : (
      <FilterOutlined rotate={90} />
    ),
  }
}

// Generate array of child leaf nodes and child sub-trees.
const mungeChildren = (
  make,
  model,
  makeCount,
  modelCount,
  keys,
  lensMappings
) => {
  let children = []
  keys.forEach((focal_length_description, focalLengthCount) => {
    let flArray = lensMappings[make][model][focal_length_description]
    let f1UniqueLen = [...new Set(flArray.map((l) => l.image_diameter))].length
    // 'Focal_Length_MM' leaf.
    if (flArray.length === 1) {
      let lens = lensMappings[make][model][focal_length_description][0]
      lens.title = `${focal_length_description}mm`
      const leaf = addLeaf(
        lens,
        `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        "focalLength"
      )
      children.push(leaf)
    }
    // `Focal_length_MM Tstop` leaves.
    else if (f1UniqueLen === 1) {
      flArray.forEach((lens, index) => {
        lens.title = `${lens.focal_length_description}mm T${lens.tstop}`
        const leaf = addLeaf(
          lens,
          `0-${makeCount}-${modelCount}-${focalLengthCount}.${index}`,
          "focalLength"
        )
        children.push(leaf)
      })
    }
    // 'Focal_Length_MM' subtree.
    else {
      const subtree = {
        title: `${focal_length_description}mm`,
        value: `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        key: `0-${makeCount}-${modelCount}-${focalLengthCount}`,
        field: "focalLength",
        isLeaf: false,
        children: flArray.map((lens, index) => {
          return addLeaf(
            lens,
            `0-${makeCount}-${modelCount}-${focalLengthCount}-${index}`,
            "imageDiameter"
          )
        }),
      }
      children.push(subtree)
    }
  })
  return children
}

const compareFocalLength = (description1, description2) => {
  // If the description is for a zoom lens (e.g. "30-90") go
  // by its wider focal length (the first one).
  const focal1 = description1.split("-")[0]
  const focal2 = description2.split("-")[0]
  // Sort by focal_length as a float, not string.
  return parseFloat(focal1) - parseFloat(focal2)
}

// Generate tree from firestore.js::getLensTypes documents:
//   make -> model -> focal_length_description (tstop) (-> image_diameter).
const mungeTreeData = (lensTypes) => {
  const treeData = []
  let lensMappings = {}

  lensTypes.forEach((lensType) => {
    if (lensMappings[lensType.make] === undefined) {
      lensMappings[lensType.make] = {}
    }
    if (lensMappings[lensType.make][lensType.model] === undefined) {
      lensMappings[lensType.make][lensType.model] = {}
    }
    if (
      lensMappings[lensType.make][lensType.model][
        lensType.focal_length_description
      ] === undefined
    ) {
      lensMappings[lensType.make][lensType.model][
        lensType.focal_length_description
      ] = []
    }
    lensMappings[lensType.make][lensType.model][
      lensType.focal_length_description
    ].push({
      title: `${friendlyImageDiameter(lensType.image_diameter)}`,
      make: lensType.make,
      model: lensType.model,
      focal_length_mm: lensType.focal_length_mm,
      focal_length_description: lensType.focal_length_description,
      calibrated_front_rear: lensType.calibrated_front_rear,
      fstop: lensType.fstop,
      tstop: lensType.tstop,
      length: lensType.length,
      image_diameter: lensType.image_diameter,
      supported: !unsupported(lensType),
      type_id: lensType.type_id,
      lenses: lensType.lenses,
    })
  })
  let makeCount = 0
  for (const make in lensMappings) {
    // 'Make' tree.
    treeData.push({
      title: make,
      value: `0-${makeCount}`,
      key: `0-${makeCount}`,
      field: "make",
      isLeaf: false,
      children: Object.keys(lensMappings[make]).map(
        (model, modelCount, makeCount) => {
          // 'Model' subtree.
          return {
            title: model,
            value: `0-${makeCount}-${modelCount}`,
            key: `0-${makeCount}-${modelCount}`,
            field: "model",
            isLeaf: false,
            children: mungeChildren(
              make,
              model,
              makeCount,
              modelCount,
              Object.keys(lensMappings[make][model]).sort(compareFocalLength),
              lensMappings
            ),
          }
        }
      ),
    })
    makeCount++
  }
  return treeData
}

export default mungeTreeData
