import { COLLECTION } from "../constants"
import { getDb, describeLensModel } from "./helpers"

const { LENS_TYPES } = COLLECTION

const lensTypes = {
  getLensTypes: async () => {
    const db = await getDb()
    const snapshot = await db
      .collection(LENS_TYPES)
      .where("make", ">", "")
      .get()
    let data = []
    snapshot.docs.forEach((doc) => {
      let doc_data = doc.data()
      doc_data = describeLensModel(doc_data)
      if (doc_data.type_id === undefined) doc_data.type_id = doc.id
      data.push(doc_data)
    })
    return data
  },
  addLensType: async (lens) => {
    const db = await getDb()
    let ref = undefined
    await db
      .collection(COLLECTION.LENS_TYPES)
      .add({ ...lens, calibrated_front_rear: null })
      .then(async (doc) => {
        ref = doc
        return await doc.update({ type_id: doc.id })
      })
    return await ref.get().then((doc) => doc.data())
  },
  // Update missing details of a a LensType and return its data.
  updateLensType: async (tracisId, lens) => {
    const db = await getDb()
    const lensTypeRef = db.collection(LENS_TYPES).doc(tracisId)
    await lensTypeRef.update({
      focal_length_mm: lens.focal_length_mm,
      image_diameter: lens.image_diameter,
      length: lens.length,
      tstop: lens.tstop,
      mount: lens.mount,
    })
    return await lensTypeRef.get().then((doc) => {
      return { ...doc.data(), type_id: tracisId }
    })
  },
  // Get a LensType's details.
  getLensType: async (typeId) => {
    const db = await getDb()
    const lensTypeDoc = await db.collection(LENS_TYPES).doc(typeId).get()
    return { ...lensTypeDoc.data(), type_id: typeId }
  },
}

export default lensTypes
