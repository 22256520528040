import React from "react"
import tracisLogo from "../../../images/tracis_horiz_white_sm.png"

const WelcomeHeader = () => {
  return (
    <a href="https://tracis.io/" target="_blank" rel="noopener noreferrer">
      <img
        src={tracisLogo}
        style={{ width: "240px" }}
        alt="Tracis, Inc. Logo"
      />
    </a>
  )
}
export default WelcomeHeader
