import React from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { Button, Divider, Input, Form } from "antd"
import { Link } from "react-router-dom"
import { ArrowRightOutlined, GoogleOutlined } from "@ant-design/icons"
import { ACTION_CODE_SETTINGS } from "../../../state/constants"
import WelcomeHeader from "../WelcomeHeader"
import { useGlobalState } from "../../../state"
import { captureException } from "@sentry/react"

const SignUp = ({
  createUserWithEmailAndPassword = () => {},
  signInWithGoogle = () => {},
  user,
} = {}) => {
  const [state, dispatch] = useGlobalState()
  const [form] = Form.useForm()
  const inviteRegistration = state.invitedUser !== false

  const updateUser = async (formValues) => {
    const displayName = formValues.displayName
    // Update Firebase Auth user data.
    await user.updateProfile({ displayName: displayName })

    // Update Firebase Auth user password.
    await user.updatePassword(formValues.password)

    // Update firestore User document.
    await db.updateUserProfile(user.uid, displayName)
    dispatch({ type: "SET_INVITED_USER", invitedUser: false })
  }

  const createUser = () => {
    const formValues = form.getFieldsValue()
    createUserWithEmailAndPassword(formValues.email, formValues.password)
      .then(async (userCredential) => {
        const user = userCredential.user
        user.sendEmailVerification(ACTION_CODE_SETTINGS)

        // Update Firebase Auth profile.
        return await user.updateProfile({ displayName: formValues.displayName })
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        alert(errorMessage)
        console.error(errorCode, errorMessage)
        captureException(error)
      })
      // Redirect to /settings and reload so global state can pick up on
      // new auth and orgId.
      .finally(() => {
        window.history.replaceState({}, "", "settings")
        window.location.reload()
      })
  }

  return (
    <div className={css.signInContainer}>
      <div className={css.module}>
        <WelcomeHeader />
        <h2>Register</h2>
        {!inviteRegistration && (
          <div>
            <Button
              type="primary"
              ghost
              onClick={signInWithGoogle}
              icon={<GoogleOutlined />}
              block
            >
              Sign up with Google
            </Button>
            <Divider>OR</Divider>
          </div>
        )}
        <Form
          form={form}
          name="register"
          onFinish={inviteRegistration ? updateUser : createUser}
          scrollToFirstError
          style={{ width: "100%" }}
          layout="vertical"
          initialValues={user}
        >
          <Form.Item
            name="displayName"
            label={<span>Full Name</span>}
            rules={[
              {
                required: true,
                message: "Full Name is required",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Invalid e-mail format",
              },
              {
                required: true,
                message: "E-mail is required",
              },
            ]}
            disabled={inviteRegistration}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject("Passwords do not match")
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" ghost block>
              Register
            </Button>
          </Form.Item>
        </Form>
        {!inviteRegistration && (
          <div>
            <Divider />
            Already registered?{"  "}
            <Link to="/signin">
              Sign In <ArrowRightOutlined />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

SignUp.propTypes = {
  /** @type{Function} Firebase function to create new user. */
  createUserWithEmailAndPassword: PropTypes.func,
  /** @type{Function} Firebase function to sign in using Google OAuth. */
  signInWithGoogle: PropTypes.func,
  /** @type{{uid: string, updateProfile: function, updatePassword: function, sendEmailVerification: fnction}} Firebase User object. */
  user: PropTypes.object,
}

export default SignUp
