import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Tabs, List, Button, Divider, Input, Row, Col, Tooltip } from "antd"
import css from "./index.module.css"
import cx from "classnames"
import { useGlobalState } from "../../../state"
import mungeTreeData from "../../../helpers/mungeTreeData"
import {
  generateTrie,
  findLenses,
  generateLensInstances,
} from "../../../helpers/search"
import useDebounce from "../../../helpers/useDebounce"
import {
  WarningOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import Loading from "../Loading"
import { SEARCH_PLACEHOLDER, UNSUPPORTED_TEXT } from "../../../state/constants"
import SerialNumberForm from "./SerialNumberForm"
import { unsupported } from "../../../helpers/lens"

const LensTree = ({
  lenses,
  triggerNewLensForm,
  addNewLens,
  handleContinue,
  scanWizard = false,
}) => {
  const { TabPane } = Tabs
  const [state, dispatch] = useGlobalState()
  const [make, setMake] = useState(null)
  const [model, setModel] = useState(null)
  const [focalLength, setFocalLength] = useState(null)
  const [serialNumber, setSerialNumber] = useState(null)
  const [filteredLenses, setFilteredLenses] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [activeKey, setActiveKey] = useState("make")
  const [trie, setTrie] = useState(undefined)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchInput = useDebounce(searchInput, 500)

  const treeClassNames = scanWizard
    ? {
        listBody: cx(css.listBody, css.scanWizard),
        paneBody: cx(css.paneBody, css.scanWizard),
        lensTreeTab: cx(css.lensTreeTab, css.scanWizard),
      }
    : {
        listBody: css.listBody,
        paneBody: css.paneBody,
        lensTreeTab: css.lensTreeTab,
      }

  const treeData = mungeTreeData(lenses)

  const TABS = {
    MAKE: "make",
    MODEL: "model",
    FOCAL_LENGTH: "focalLength",
    IMAGE_DIAMETER: "imageDiameter",
    SERIAL_NUMBER: "serialNumber",
  }

  const handleTreeSelect = (_e, item) => {
    switch (item.field) {
      case TABS.MAKE:
        setMake(item)
        setActiveKey(TABS.MODEL)
        break
      case TABS.MODEL:
        setModel(item)
        setActiveKey(TABS.FOCAL_LENGTH)
        break
      case TABS.FOCAL_LENGTH:
        setFocalLength(item)
        if (item.children) setActiveKey(TABS.IMAGE_DIAMETER)
        break
      default:
        break
    }
    if (item.isLeaf) {
      const {
        make,
        model,
        focal_length_mm,
        supported,
        type_id,
        calibrated_front_rear,
        image_diameter,
        tstop,
        length,
        lens_id,
        serial_number,
        uniqueIdentifier,
      } = item
      const lens = {
        make,
        model,
        focal_length_mm,
        supported,
        type_id,
        calibrated_front_rear,
        image_diameter,
        tstop,
        length,
        lens_id,
        serial_number,
        uniqueIdentifier,
      }
      lens.id = lens.lens_id

      if (state.currentScanLens !== null) {
        const el = document.getElementById(
          state.currentScanLens.uniqueIdentifier
        )
        el !== null && el.classList.remove(css.selected)
      }

      dispatch({
        type: "SET_CURRENT_SCAN_LENS",
        lens: lens,
      })
    } else {
      resetCurrentScanLens()
    }
  }

  const handleLensTypeSelect = (e, item) => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: item })
  }

  const resetCurrentScanLens = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
  }

  // Clicking on Tab title resets to tab title step in lens selection.
  const handleTabTitleClick = (title) => {
    switch (title) {
      case TABS.MAKE:
        setMake(null) // eslint-disable-next-line no-fallthrough
      case TABS.MODEL:
        setModel(null) // eslint-disable-next-line no-fallthrough
      case TABS.FOCAL_LENGTH:
        setFocalLength(null)
        break
      case TABS.SERIAL_NUMBER:
        setSerialNumber(null)
        break
      default:
        break
    }
    resetCurrentScanLens()
    setActiveKey(title)
  }

  const selected = (item) =>
    state.currentScanLens !== null &&
    state.currentScanLens.type_id === item.type_id

  // Display lenses in tree form.
  const tree = (data = treeData) => {
    return (
      <List
        dataSource={data}
        bordered={false}
        renderItem={(item) => (
          <Row
            onClick={(e) => handleTreeSelect(e, item)}
            className={cx(css.lensTreeItem, {
              [css.selected]: selected(item),
            })}
          >
            {item.isLeaf && scanWizard ? (
              unsupported(item) ? (
                <Tooltip placement="bottom" title={UNSUPPORTED_TEXT}>
                  <WarningOutlined style={{ marginRight: "16px" }} />
                </Tooltip>
              ) : (
                <FilterOutlined style={{ marginRight: "16px" }} rotate={90} />
              )
            ) : (
              ""
            )}

            {item.title}
            {selected(item) && (
              <SerialNumberForm
                addNewLens={addNewLens}
                handleContinue={handleContinue}
              />
            )}
          </Row>
        )}
      ></List>
    )
  }

  // Display lenses in list form.
  const list = (lensesList = filteredLenses) => {
    return lensesList.length === 0 ? (
      <div className="centeredVH">
        Don&lsquo;t see your lens?
        <br />
        <br />
        <Button onClick={triggerNewLensForm}>Add one here</Button>
      </div>
    ) : (
      <List
        dataSource={lensesList}
        bordered={false}
        renderItem={(item) => (
          <Row
            onClick={(e) => handleLensTypeSelect(e, item)}
            className={cx(css.lensTreeItem, {
              [css.selected]: item === serialNumber,
            })}
            id={item.uniqueIdentifier}
          >
            {item.emboldened_title || item.title}
            {state.currentScanLens !== null &&
              state.currentScanLens.type_id === item.type_id && (
                <SerialNumberForm
                  addNewLens={addNewLens}
                  handleContinue={handleContinue}
                  scanWizard={scanWizard}
                />
              )}
          </Row>
        )}
      ></List>
    )
  }

  // Create Component for lens title based on serial_number, supported fields
  // and treeType. Implemented as Columns inside Rows for consistent alignment
  // of icons, text, and buttons.
  const lensTitle = (lens) => {
    const icon =
      lens.serial_number === undefined ? (
        unsupported(lens) ? (
          <WarningOutlined />
        ) : (
          <PlusCircleOutlined />
        )
      ) : (
        <FilterOutlined rotate={90} />
      )
    return (
      <div>
        <Col span={2} className={css.lensIcon}>
          {icon}
        </Col>
        <Col span={22} className={css.lensListBody}>
          {lens.boldTitle || lens.flatTitle}
        </Col>
      </div>
    )
  }

  // Add fields expected by List when viewing list of search results (these fields
  // already exist in lens tree viewing format).
  const addWizardLensFields = (lens) => {
    lens = {
      ...lens,
      title: lensTitle(lens),
      field: TABS.FOCAL_LENGTH,
      isLeaf: true,
    }
    return lens
  }

  // Bold lens title substring(s) that match search term(s).
  const embolden = (lenses) => {
    const terms = searchInput
      .split(" ")
      .filter((x) => x !== "")
      .map((y) => y.toLowerCase())

    lenses.forEach((lens) => {
      let currentTitle = lens.flatTitle
      const currentTitleLower = currentTitle.toLowerCase()
      let indexes = {}
      terms.forEach((term) => {
        let i = -1
        while ((i = currentTitleLower.indexOf(term, i + 1)) !== -1) {
          if (i === 0 || currentTitleLower[i - 1] === " ") {
            indexes[i] = term.length
          }
        }
      })

      let t = currentTitle.split("")
      let ret = []
      let j = 0
      while (j < t.length) {
        if (indexes[j] !== undefined) {
          ret.push(
            <b key={`${lens.uniqueIdentifier}-${j}`}>
              {currentTitle.slice(j, j + indexes[j])}
            </b>
          )
          j += indexes[j]
        } else {
          ret.push(t[j])
          j += 1
        }
      }
      const boldTitle = ret.map((x) => x)
      lens.title = lensTitle({ ...lens, boldTitle: boldTitle })
    })
    return lenses
  }

  // Generate search trie upon initial lens load.
  useEffect(() => {
    let lensInstances = lenses
    if (scanWizard) {
      lensInstances = generateLensInstances(lenses, false)
      lensInstances = lensInstances.map((lens) => addWizardLensFields(lens))
    }

    const ts = generateTrie(lensInstances, true)
    setFilteredLenses(lensInstances)
    setTrie(ts)
    setIsSearching(false)
  }, [lenses])

  // Handle search input with useDebounce.
  useEffect(
    () => {
      if (debouncedSearchInput) {
        setIsSearching(true)
        findLenses(trie, debouncedSearchInput).then((filtered) => {
          filtered = embolden(filtered)
          setFilteredLenses(filtered)
          setIsSearching(false)
        })
      } else {
        setFilteredLenses([])
      }
    },
    [debouncedSearchInput] // Only call effect if debounced search term changes.
  )

  const handleSearchInputChange = (event) => {
    const input = event.target.value
    setSearchInput(event.target.value)
    setIsSearching(true)
    if (input === "") {
      setFilteredLenses(lenses)
      setIsSearching(false)
    }
  }
  return (
    <div>
      <div className={css.lensTree}>
        <div className="App-header">
          <div className={css.headerContainer}>
            <Input
              onChange={handleSearchInputChange}
              disabled={lenses.length === 0}
              placeholder={SEARCH_PLACEHOLDER}
              allowClear
              style={{ marginRight: "32px" }}
            />
          </div>
        </div>
        <div>
          <Divider
            style={{
              background: "#34383b",
              marginTop: "16px",
              marginBottom: "0px",
            }}
          ></Divider>
          {searchInput === "" && !isSearching ? (
            <Tabs
              activeKey={activeKey}
              onTabClick={handleTabTitleClick}
              className={treeClassNames.lensTreeTab}
            >
              <TabPane tab="Make" key={TABS.MAKE} className={css.lensTreeTab}>
                <div className={treeClassNames.paneBody}>{tree()}</div>
              </TabPane>
              <TabPane
                tab="Model"
                disabled={make === null}
                key={TABS.MODEL}
                className={css.lensTreeTab}
              >
                <div className={treeClassNames.paneBody}>
                  {make && tree(make.children)}{" "}
                </div>
              </TabPane>
              <TabPane
                tab="Focal Length"
                disabled={model === null}
                key={TABS.FOCAL_LENGTH}
                className={css.lensTreeTab}
              >
                <div className={treeClassNames.paneBody}>
                  {make && model && tree(model.children)}{" "}
                </div>
              </TabPane>
              {make && model && focalLength && !focalLength.isLeaf && (
                <TabPane
                  tab="Image Circle"
                  key={TABS.IMAGE_DIAMETER}
                  className={css.lensTreeTab}
                >
                  <div className={treeClassNames.paneBody}>
                    {tree(focalLength.children)}{" "}
                  </div>
                </TabPane>
              )}
            </Tabs>
          ) : (
            <div className={treeClassNames.listBody}>
              {isSearching ? <Loading empty /> : list(filteredLenses)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LensTree.propTypes = {
  /** @type{object[]} Array of LensType objects. If ScanWizard each
    LensType will include an array of Lens instances. */
  lenses: PropTypes.array,
  /** @type{Function} Handler for "Continue" button click in ScanWizard. This
    is a drilled prop. */
  handleContinue: PropTypes.func,
  /** @type{Function} Function to add new Lens instance. This is a drilled 
    prop. */
  addNewLens: PropTypes.func,
  /** @type{Function} Function to trigger AddLens modal. */
  triggerNewLensForm: PropTypes.func,
  /** @type{Boolean=false} Lenses tree or LensTypes tree. */
  scanWizard: PropTypes.bool,
}

export default LensTree
