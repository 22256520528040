import { COLLECTION } from "../constants"
import { getDb, getStorage, timestamp } from "./helpers"

const files = {
  addFile: async (orgId, lensId, file, uid, displayName) => {
    const db = await getDb()
    const storage = await getStorage()

    const fileRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.FILES)
      .add({
        timestamp: timestamp(),
        name: file.name,
        type: file.type,
        userId: uid,
        userName: displayName,
      })

    const fileObject = await fileRef.get()
    const storageURL = `Organizations/${orgId}/Files/${fileObject.id}/${file.name}`
    const storageRef = storage.ref(storageURL)

    await storageRef.put(file).then((res) => {
      res.ref.getDownloadURL().then((url) => {
        console.log(url)
        fileRef.update({
          storageURL: storageURL,
          fileLink: url,
        })
      })
    })
  },
  getFiles: async (orgId, lensId) => {
    const db = await getDb()
    const snapshot = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.FILES)
      .orderBy("timestamp")
      .get()
    const files = []
    snapshot.docs.forEach((doc) => {
      files.push({ id: doc.id, ...doc.data() })
    })

    return files
  },
  onFiles: async (orgId, lensId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.FILES)
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const files = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        onChange(files)
      })
  },
  deleteFile: async (orgId, lensId, fileId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.FILES)
      .doc(fileId)
      .delete()
      .then(() => console.log(`File ${fileId} deleted.`))
  },
}

export default files
