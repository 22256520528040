import React, { useEffect } from "react"
import { notification } from "antd"
import { firestore as db } from "../../../state/firebase"
import { useGlobalState } from "../../../state"
import { notificationDate } from "../../../helpers/date"
import { SCAN_STATUS } from "../../../state/constants"
import { lensTitle } from "../../../helpers/lens"

const ScanNotification = () => {
  const [state] = useGlobalState()

  const notify = (scan) => {
    let config = { duration: 8 }
    let title = lensTitle(scan) // ARRI Macro 50mm...
    title += notificationDate(scan.timestamp.seconds) // ...from yesterday.
    switch (scan.status) {
      case SCAN_STATUS.COMPLETED:
        // Scan complete. Link to ScanHistory.
        config.message = "Scan Complete"
        config.description = `The scan of the ${title} has completed successfully.`
        config.onClick = () => {
          window.open(`/scan_history`, "_blank")
        }
        notification.info(config)
        break
      case SCAN_STATUS.PROCESSED:
      case SCAN_STATUS.PARTIALLY_PROCESSED:
      case SCAN_STATUS.READY_TO_REVIEW:
        // Scan analysis complete. Link to Inventory page for scan lens and date.
        config.message = "Scan Analyzed"
        config.description = `The ${title} has been analyzed and is ready to view.`
        config.onClick = () => {
          window.open(
            `/inventory/${scan.lens_ref.id}?date_a=${scan.timestamp.seconds}`,
            "_blank"
          )
        }
        notification.success(config)
        break
      case SCAN_STATUS.BAD:
        // Error while scanning. Link to ScanHistory.
        config.message = "Error Scanning Lens"
        config.description = `Scanner encountered an error while scanning the ${title}.`
        config.onClick = () => {
          window.open(`/scan_history`, "_blank")
        }
        notification.error(config)
        break
      default:
        console.warn("Unsupported status change passed to ScanNotification.")
    }
  }

  // Empty callbacks for onScansChange. We're currently only interested in changes
  // of type "modified" -- namely, scan status changes.
  const onAdded = () => {}
  const onDone = () => {}

  useEffect(() => {
    if (state.userOrgId === undefined) return

    db.onScansChange(state.userOrgId, onAdded, notify, onDone)
  }, [state.userOrgId])

  return <div></div>
}

export default ScanNotification
