import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Checkbox, Typography } from "antd"
import Header from "../../common/Header"
import Lens from "../../common/Lens"
import { generateScanID } from "../../../helpers/generateScanID"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import { MACHINE, STEPS } from "../../../state/constants"
import { captureException } from "@sentry/react"

const startVerbs = [
  "Commence!",
  "Initiate!",
  "Engage!",
  "Beam me up!",
  "Do the thing!",
  "Dance!",
]

const verb = startVerbs[Math.floor(Math.random() * startVerbs.length)]

export default function FinalChecklist({ setStep, user }) {
  const [checklistEnabled, setChecklistEnabled] = useState(true)
  const [state, dispatch] = useGlobalState()
  const [checkList, setCheckList] = useState({
    iris: false,
    focus: false,
    door: false,
    zoom: !state.currentScanLens.focal_length_max > 0, //If zoom, this will be set to false so it is included in the checklist, otherwise true and not included.
  })

  const onStatusChecklistChange = (doc) => {
    // We enable our checklist, but only if the machine is ready to start another scan.
    setChecklistEnabled(doc.status === MACHINE.READY)
  }

  useEffect(() => {
    db.onScannerStatusChange(
      state.userOrgId,
      state.scanner.id,
      onStatusChecklistChange
    )
  }, [state.scanner])

  const canContinue = Object.values(checkList).reduce((acc, val) => acc && val)

  const reformatLensKeys = (oldLens) => {
    const defaults = {
      fstop: 1.8,
      image_diameter: 32,
      mount: "PL",
    }

    // Remove object fields that are null
    Object.keys(oldLens).forEach(
      (key) => oldLens[key] === null && delete oldLens[key]
    )

    const lens = Object.assign({}, defaults, oldLens)
    lens.focal_length = oldLens.focal_length_mm
    delete lens["calibrated_front_rear"]
    delete lens["focal_length_mm"]
    if (lens.newLens) delete lens["newLens"]

    return lens
  }

  const handleClick = () => {
    if (state.sandbox) return setStep(STEPS.SANDBOX_SCAN)

    const { currentScanLens } = state
    const {
      make,
      model,
      focal_length_description,
      type_id,
      newLens,
    } = currentScanLens
    let lens

    if (newLens) {
      lens = currentScanLens
    } else {
      lens = state.lensTypes.find((lensType) => lensType.type_id === type_id)
    }
    if (lens === undefined)
      throw new Error(
        `No lenses found of type: '${make}' '${model}' '${focal_length_description}mm'`
      )
    lens.lens_id = currentScanLens.lens_id
    lens.serial_number = currentScanLens.serial_number
    const scanID = generateScanID()
    dispatch({ type: "SET_SCAN_ID", scanID: scanID })
    db.startScanning(
      state.userOrgId,
      state.scanner.id,
      reformatLensKeys(lens),
      user,
      scanID,
      state.calibrationScan
    )
      .then((res) => console.log(res))
      .catch((err) => {
        // This is ripped from firestore/scans.js so we can see exactly which object
        // firestore is barfing on.
        // https://sentry.io/organizations/tracis/issues/2096065685/?environment=beta&project=5553965&query=is%3Aunresolved
        let scanner_doc_content = {
          user_scan_params: {
            lens: { ...lens, supported: true },
            scan_id: scanID,
            user_id: user.uid,
            org_id: state.userOrgId,
            calibration: state.calibrationScan,
          },
          author_email: user.email,
          percent_complete: 0,
        }
        let obj = scanner_doc_content.user_scan_params.lens
        Object.keys(obj).forEach((key) =>
          obj[key] === undefined ? delete obj[key] : {}
        )

        captureException({
          component: "FinalChecklist",
          scanID,
          scanner_doc_content,
          ...err,
        })
      })

    setStep(STEPS.SCANNING)
  }

  return (
    <div>
      <Header>Get ready to start a scan!</Header>
      <div className={css.scanner}>{state.scanner.human_name}</div>
      <div className={css.lens}>
        <Lens lens={state.currentScanLens} />
      </div>
      {checklistEnabled ? (
        <div>
          <div className={css.checklist}>
            <div className={css.form}>
              {state.currentScanLens.focal_length_max !== undefined &&
                state.currentScanLens.focal_length_max > 0 && (
                  <div>
                    <Checkbox
                      onChange={(e) =>
                        setCheckList({ ...checkList, zoom: e.target.checked })
                      }
                    >
                      <strong>Set zoom to lowest possible focal length.</strong>
                    </Checkbox>
                    <br />
                    <br />
                  </div>
                )}
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, iris: e.target.checked })
                }
              >
                Iris Wide Open
              </Checkbox>
              <br />
              <br />
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, focus: e.target.checked })
                }
              >
                Focus At Infinity
              </Checkbox>
              <br />
              <br />
              <Checkbox
                onChange={(e) =>
                  setCheckList({ ...checkList, door: e.target.checked })
                }
              >
                Close Door
              </Checkbox>
            </div>
          </div>
          <div className={css.button}>
            <Button
              size="large"
              type="primary"
              ghost
              onClick={handleClick}
              disabled={!canContinue}
              block
            >
              {canContinue ? verb : "Confirm Checklist to Continue"}
            </Button>
          </div>
        </div>
      ) : (
        <Typography>{`${state.scanner.human_name} is currently in use. You can start your scan from here as soon as the scan in progress finishes.`}</Typography>
      )}
    </div>
  )
}

FinalChecklist.propTypes = {
  /** @type{Function} Set ScanWizard step. */
  setStep: PropTypes.func,
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
}
