import { COLLECTION } from "../constants"
import { getDb, getStorage, timestamp, dataURLtoBlob } from "./helpers"

const scratches = {
  addScratch: async (
    orgId,
    lensId,
    dataURL,
    surface,
    setEditing,
    uid,
    displayName
  ) => {
    const db = await getDb()
    const storage = await getStorage()
    const scratchRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.SCRATCHES)
      .add({
        timestamp: timestamp(),
        surface,
        userId: uid,
        userName: displayName,
      })

    const scratchObject = await scratchRef.get()
    const storageURL = `Organizations/${orgId}/Scratches/${scratchObject.id}/scratchSheet.png`
    const storageRef = storage.ref(storageURL)
    const blob = dataURLtoBlob(dataURL)
    storageRef.put(blob).then((res) => {
      res.ref.getDownloadURL().then((url) => {
        scratchRef
          .update({
            image: storageURL,
            imageLink: url,
          })
          .then(() => setEditing(false))
      })
    })
    return { id: scratchObject.id }
  },
  getScratch: async (orgId, lensId, scratchId) => {
    const db = await getDb()
    const scratchRef = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.SCRATCHES)
      .doc(scratchId)
      .get()

    const scratch = scratchRef.data()

    if (scratch === undefined) return

    return { id: scratchRef.id, ...JSON.parse(scratch.scratchData) }
  },
  onScratches: async (orgId, lensId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.SCRATCHES)
      .orderBy("timestamp")
      .onSnapshot((snapshot) => {
        const scratches = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        onChange(scratches)
      })
  },
  getScratches: async (orgId, lensId) => {
    const db = await getDb()
    const lensRef = db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)

    const snapshot = await lensRef
      .collection(COLLECTION.SCRATCHES)
      .orderBy("timestamp")
      .get()

    const lens = await lensRef.get()

    const lensData = lens.data()
    const lensType = await lensData.lens_type_ref.get()
    const scratchesForLens = {
      id: lens.id,
      lens: { ...lensData, id: lens.id },
      lensType: lensType.data(),
      frontImages: [],
      rearImages: [],
    }
    snapshot.docs.forEach((doc) => {
      const scratch = doc.data()
      if (scratch.surface === "Front") {
        scratchesForLens.frontImages.push(scratch.imageLink)
      }

      if (scratch.surface === "Rear") {
        scratchesForLens.rearImages.push(scratch.imageLink)
      }

      if (
        !("timestamp" in scratchesForLens) ||
        scratch.timestamp.seconds > scratchesForLens.seconds
      ) {
        scratchesForLens.timestamp = scratch.timestamp
      }
    })

    return scratchesForLens
  },
  deleteScratch: async (orgId, lensId, scratchId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.LENSES)
      .doc(lensId)
      .collection(COLLECTION.SCRATCHES)
      .doc(scratchId)
      .delete()
      .then(() => console.log(`Scratch ${scratchId} deleted.`))
  },
}

export default scratches
