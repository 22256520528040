import React from "react"
import css from "./index.module.css"
import Header from "../Header"

export default function LenserHeader() {
  return (
    <Header>
      Select A Lens
      <br />
      <div className={css.subHeader}>
        Powered by{" "}
        <a
          href="https://www.lenserapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={css.lenserLogo} src="lenser.svg" alt="Lenser logo" />
        </a>
      </div>
    </Header>
  )
}
