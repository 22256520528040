import React, { createContext, useContext, useReducer } from "react"
import PropTypes from "prop-types"

export const StateContext = createContext()

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

StateProvider.propTypes = {
  reducer: PropTypes.object,
  initialState: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export const useGlobalState = () => useContext(StateContext)
