import React, { FC } from "react"
import Slider from "react-slick"
import css from "./ExternalImageViewer.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface Props {
  imageUrls: Array<string>
}

const ExternalImageViewer: FC<Props> = ({ imageUrls }: Props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  }
  return (
    <div className={css.container}>
      <Slider {...settings}>
        {imageUrls.map((imageUrl: string) => (
          <div key={imageUrl}>
            <img alt={imageUrl} src={imageUrl} className={css.rotate} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ExternalImageViewer
