export const MACHINE = {
  BOOTING: "booting",
  READY: "ready to scan",
  SCANNING: "scanning",
  UNAVAILABLE: "unavailable",
  STARTING_SERVICE: "preparing for service",
  READY_TO_SERVICE: "ready to service",
  STOPPING_SERVICE: "ending service",
}

export const CURRENT_SCAN = {
  STARTED: "started",
  COMPLETED: "completed",
  STOPPED: "stopped",
  STOPPING: "stopping",
  ERROR: "error",
}

export const SCAN_STATUS = {
  STARTED: "STARTED",
  STOPPED: "STOPPED",
  UPLOADING: "UPLOADING",
  COMPLETED: "COMPLETED",
  READY_TO_REVIEW: "READY TO REVIEW",
  PARTIALLY_PROCESSED: "PARTIALLY PROCESSED",
  PROCESSED: "PROCESSED",
  CALIBRATED: "CALIBRATED",
  BAD: "BAD",
}

export const INSTRUCTION = {
  START: "start",
  STOP: "stop",
  EMPTY: "",
  START_DEMO: "demo",
  START_SERVICE: "service",
  STOP_SERVICE: "home",
}

export const STEPS = {
  SELECT_SCANNER: 0,
  SELECT_LENS: 1,
  ADD_LENS: 2,
  CALIBRATE_LENS: 4,
  FINAL_CHECKLIST: 5,
  SCANNING: 3,
  SELECT_SERIAL_NUMBER: 6,
  SANDBOX_SCAN: 7,
}

export const DEMO_LENS = {
  make: "Celere",
  model: "HS",
  serial_number: "DEMOLENS",
  fstop: 1.8,
  focal_length: 50,
  length: 140,
  image_diameter: 32,
  curvature: 1.0,
  tstop: 2,
}

export const COLLECTION = {
  ORGANIZATIONS: "Organizations",
  SCANS: "Scans",
  LENSES: "Lenses",
  SCANNERS: "Scanners",
  LENS_TYPES: "LensTypes",
  USERS: "Users",
  SHARING_LINKS: "SharingLinks",
  SHARED_DOCS: "SharedDocuments",
  SCRATCHES: "Scratches",
  NOTES: "Notes",
  FILES: "Files",
  INVITES: "Invites",
}

export const AUTO_RELEASE = {
  orgIds: ["peWt74RTHuEtXjHVOIML"],
  focal_length: 50,
}

export const DEFAULT_REFS = {
  USER: {
    data: () => {
      return { displayName: "Unknown User", doc_id: "unknown" }
    },
  },
  LENS_TYPE: {
    data: () => {
      return {
        make: "Unknown make",
        model: "Unknown model",
        focal_length_mm: "unknown ",
      }
    },
  },
  LENS: {
    data: () => {
      return {
        serial_number: "unknown",
      }
    },
  },
  SCANNER: {
    data: () => {
      return { human_name: "Unknown", id: "unknown" }
    },
  },
}

export const LENS_TYPE_FIELDS = {
  make: {
    title: "Make",
    id: "make",
    type: "string",
  },
  model: {
    title: "Model",
    id: "model",
    type: "string",
  },
  focal_length_mm: {
    title: "Focal Length",
    id: "focal_length_mm",
    type: "int",
  },
  focal_length_max: {
    title: "Focal Length Max",
    id: "focal_length_max",
    type: "int",
  },
  length: {
    title: "Physical Length",
    id: "length",
    type: "int",
  },
  fstop: {
    title: "F-Stop",
    id: "fstop",
    type: "int",
  },
  tstop: {
    title: "T-Stop",
    id: "tstop",
    type: "float",
  },
  tstop_max: {
    title: "T-Stop Max",
    id: "tstop_max",
    type: "float",
  },
  mount: {
    title: "Mount",
    id: "mount",
    type: "string",
  },
  image_diameter: {
    title: "Image Diameter",
    id: "image_diameter",
    type: "int",
  },
  anamorphic: {
    title: "Anamorphic Lens",
    id: "anamorphic",
    type: "bool",
  },
  glass_make: {
    title: "Glass Make",
    id: "glass_make",
    type: "string",
  },
  housing_make: {
    title: "Housing Make",
    id: "housing_make",
    type: "string",
  },
}

export const SEARCH_PLACEHOLDER = "e.g.: ARRI Ultra Prime 50mm 1101"

export const SCRATCH_COLORS = {
  WHITE: "#D8D8D8",
  RED: "#E02020",
  GREEN: "#6DD400",
  BLUE: "#32C5FF",
  PURPLE: "#A288FF",
}

// Generated with `[...new Set(state.lensTypes.map(x=> x.mount).flat())].sort()`.
export const MOUNTS = [
  "41 mm Bayonet",
  "Aaton",
  "Arri Bayonet",
  "B4",
  "BNCR",
  "Bayonet",
  "C",
  "E",
  "EF",
  "F",
  "FZ",
  "IMAX",
  "IMS",
  "LP",
  "LPL",
  "M",
  "MFT",
  "Maxi PL",
  "PL",
  "PL-LDS",
  "PL-Stainless Steel",
  "PL-Titanium",
  "PV",
  "PV65",
  "PV70",
  "System 65",
  "UMS",
  "X",
  "XPL",
]

export const IMAGE_DIAMETERS = [
  { value: 15, text: "Super 16" },
  { value: 32, text: "Super 35" },
  { value: 43, text: "Full Frame" },
]

export const DEFAULT_USER = {
  uid: "UWSNujYpnEXdA3ob9kWosJJW1MV2",
  email: "defaultuser@tracis.io",
  password: "IAmTheSandboxUser",
}

export const ACTION_CODE_SETTINGS = {
  url: `${window.location.origin}/settings`,
  handleCodeInApp: false,
}

export const NEW_PASSWORD_ACTION_CODE_SETTINGS = {
  url: `${window.location.origin}/settings?resetPassword=true`,
  handleCodeInApp: true,
}

export const UNSUPPORTED_TEXT = `We support almost all spherical lenses of 
focal length 20mm and above. Support for 20mm sphericals as well as 
anamorphics is in the works.`
