import React, { useState, useEffect } from "react"
import { useGlobalState } from "../../../state"
import { Link, useLocation } from "react-router-dom"
import { Menu, Layout } from "antd"
import {
  HistoryOutlined,
  ScanOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import tracisLogo from "../../../images/tracis_icon_white_sm.png"
import "./index.css"

export default function LeftNav() {
  const [state, dispatch] = useGlobalState()
  const [currentLocation, setCurrentLocation] = useState("")
  const { Sider } = Layout
  const { Item } = Menu
  const location = useLocation()

  useEffect(() => {
    const rootPath = location.pathname.split("/")[1]
    setCurrentLocation(`/${rootPath}`)
  }, [location.pathname])

  const toggleCollapsed = () => {
    dispatch({ type: "SET_NAV_COLLAPSED", collapsed: !state.collapsed })
  }

  // TODO: fix iOS double tap issue: first touch is hover action, second touch is click.
  // The code below accomplishes this result but causes a noticeable repaint.
  // Remove this comment and the code below when a solution is implemented.
  // const followLink = (event) => {
  //   event.preventDefault()
  //   const path = event.target.href
  //   window.location = path
  // }

  return (
    <Sider
      collapsed={state.collapsed}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="tracisLogo" onClick={toggleCollapsed}>
        <img src={tracisLogo} alt="Tracis Logo" height={36} />
      </div>
      <Menu selectedKeys={[currentLocation]} mode="inline">
        <Item key="/inventory" id="tour-step-8">
          <Link to="/inventory">
            <SearchOutlined id="tour-step-9-trigger" />
            <span>Inventory</span>
          </Link>
        </Item>
        {state.scanners.length > 0 && (
          <Item key="/scan">
            <Link to="/scan">
              <ScanOutlined />
              <span>Scan</span>
            </Link>
          </Item>
        )}
        {state.scanners.length > 0 && (
          <Item key="/scan_history">
            <Link to="/scan_history">
              <HistoryOutlined />
              <span>Scan History</span>
            </Link>
          </Item>
        )}

        {!state.sandbox && (
          <Item key="/settings" id="forceSettingToBottomOfLeftNav">
            <Link to="/settings">
              <SettingOutlined />
              <span>Settings</span>
            </Link>
          </Item>
        )}
      </Menu>
    </Sider>
  )
}
