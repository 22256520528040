import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import LenserHeader from "../../common/LenserHeader"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import cx from "classnames"

import Inventory from "../../Inventory"

export default function SelectLens({ handleContinue }) {
  const [state] = useGlobalState()

  return (
    <div className={css.container}>
      <LenserHeader />

      <div className={css.lensTreeBody}>
        <Inventory handleContinue={handleContinue} scanWizardTree />
      </div>

      <div className="continue-container">
        <Button
          type="primary"
          ghost
          onClick={() => handleContinue(state.currentScanLens)}
          className={cx("continue", css.continueButton)}
          disabled={!state.currentScanLens}
          block
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

SelectLens.propTypes = {
  /** @type{Function} Handler for "Continue" button click in ScanWizard. This 
    is a drilled prop. */
  handleContinue: PropTypes.func,
}
