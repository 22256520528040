import React from "react"
import PropTypes from "prop-types"
import { getAuth } from "../../../state/firebase/helpers"
import { Button } from "antd"

const ResetPassword = ({ user }) => {
  const sendResetPasswordEmail = async () => {
    const auth = await getAuth()
    const emailAddress = user.email
    auth.sendPasswordResetEmail(emailAddress).then(() => {
      alert(`reset password link set to ${emailAddress}`)
    })
  }
  return (
    <Button type="link" onClick={sendResetPasswordEmail}>
      Reset Password
    </Button>
  )
}

ResetPassword.propTypes = {
  /** @type{{email: string}} User object. */
  user: PropTypes.object,
}

export default ResetPassword
