import lenses from "./lenses"
import lensTypes from "./lensTypes"
import organizations from "./organizations"
import scanners from "./scanners"
import scans from "./scans"
import sharingLinks from "./sharingLinks"
import users from "./users"
import files from "./files"
import invites from "./invites"

export const firestore = {
  ...lenses,
  ...lensTypes,
  ...organizations,
  ...scanners,
  ...scans,
  ...sharingLinks,
  ...users,
  ...files,
  ...invites,
}
