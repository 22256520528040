// In order for the scan images to take up the entire screen real estate we need to
// use Javascript to find the browser window width and height, then use those values
// in calculating inline styles for image width/height and viewer window width/height.
const getInlineStyles = (width, height, compareScans, headerHeight = 70) => {
  const dividerThickness = 4
  let imageInlineStyling = {}
  let windowInlineStyling = {}
  let titleInlineStyling = {
    position: "absolute",
    zIndex: "100",
    right: "16px",
    // Account for address bar height if app is not in fullscreen mode, otherwise
    // the date is out of view beyond the bottom of the window.
    bottom: "0px",
  }
  const fullWidth = width // Full container width in pixels.
  const fullWidthInPixels = `${fullWidth}px`
  const halfWidth = (width - dividerThickness) / 2 // Half container width minus divider in pixels.
  const halfWidthInPixels = `${halfWidth}px`
  const fullHeight = height - headerHeight // Full container height in pixels.
  const fullHeightInPixels = `${fullHeight}px`
  const halfHeight = (height - headerHeight - dividerThickness) / 2 // Half container height minus divider in pixels.
  const halfHeightInPixels = `${halfHeight}px`
  if (compareScans) {
    // View scans side-by-side.
    if (width > height) {
      imageInlineStyling = {
        width: fullWidthInPixels,
        height: fullWidthInPixels,
      }
      windowInlineStyling = {
        width: halfWidthInPixels,
        height: fullHeightInPixels,
        overflow: "hidden",
      }
    }
    // View scans stacked.
    else {
      imageInlineStyling = {
        height: fullHeightInPixels,
        width: fullHeightInPixels,
      }
      windowInlineStyling = {
        height: halfHeightInPixels,
        width: fullWidthInPixels,
        overflow: "hidden",
      }
    }
  } else {
    if (height >= width) {
      imageInlineStyling = {
        height: fullHeightInPixels,
        minWidth: fullHeightInPixels,
      }
      windowInlineStyling = {
        height: fullHeightInPixels,
        width: fullWidthInPixels,
        overflow: "hidden",
      }
    } else {
      imageInlineStyling = {
        minWidth: fullWidthInPixels,
        height: fullWidthInPixels,
      }
      windowInlineStyling = {
        width: fullWidthInPixels,
        height: fullHeightInPixels,
        overflow: "hidden",
      }
    }
  }
  // Set image minimum scale to be proportional to its smallest dimension.
  const style = {
    image: imageInlineStyling,
    window: windowInlineStyling,
    title: titleInlineStyling,
  }
  return style
}

export default getInlineStyles
