import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import { DEFAULT_REFS, DEFAULT_USER } from "../constants"
import { captureException, setContext } from "@sentry/react"

export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}

export const timestamp = () => firebase.firestore.Timestamp.now()

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const arraysEqual = (array1, array2) => {
  if (array1 === undefined && array2 !== undefined) return false
  if (array2 === undefined && array1 !== undefined) return false
  return (
    array1.length === array2.length &&
    array1.every((value, i) => value === array2[i])
  )
}

// Take firestore references, get their contents and add them to the scan object.
export const expandScanRefs = async (scans) => {
  const scanRefs = scans.map((scan) => getScanRefs(scan))
  const fulfilledScanRefs = await Promise.all(scanRefs)

  return fulfilledScanRefs.map((ref, index) =>
    associateScanWithRefs(scans[index], ref)
  )
}

export const validScan = (scan) => {
  return !(
    scan.lens_ref === undefined ||
    scan.lens_ref === null ||
    scan.lens_type_ref === undefined ||
    scan.lens_type_ref === null ||
    scan.user_ref === undefined ||
    scan.user_ref === null ||
    scan.scanner_ref === undefined ||
    scan.scanner_ref === null ||
    scan._id.startsWith("test_") ||
    scan.hidden === true
  )
}

export const getImagesFromCloudStorage = async (scans) => {
  let image_promises = []
  const cloudStorage = await getStorage()
  for (let scan of scans) {
    // Get Cloud Storage URLs.
    for (let surface of Object.values(scan.surfaces)) {
      if (surface.image !== undefined) {
        // Generate Cloud Storage URL from image path.
        let gsReference = cloudStorage.ref(surface.image)
        image_promises.push(gsReference.getDownloadURL())
      } else {
        // No image (still_processing).
        image_promises.push(undefined)
      }
    }
  }

  // Generate access tokens for images, create URLs with tokens.
  let surface_images = await Promise.all(
    image_promises.map((gs) =>
      gs
        ? gs.catch((error) => {
            console.error("error downloading image:", error)
            captureException(error)
            return undefined
          })
        : undefined
    )
  )

  for (let scan of scans) {
    let surfaces = Object.values(scan.surfaces)
    for (let i = 0; i < surfaces.length; i++) {
      // Associate returned URL with correct surface.
      surfaces[i].image_url = surface_images.shift()
    }
  }
  return scans
}

export const associateScanWithRefs = (scan, refsArray) => {
  const lensType = refsArray[0].data()
  const lens = refsArray[1].data()
  const user = refsArray[2].data()
  const scanner = refsArray[3].data()
  const externalImageLink = refsArray[4]

  scan.lens = describeLensModel(lensType)
  scan.lens.serialNumber = lens === undefined ? "UNKNOWN" : lens.serial_number
  scan.scanner = { ...scanner, doc_id: scan.scanner_ref.id }
  scan.user = { ...user, doc_id: scan.user_ref.id }
  scan.externalImageLink = externalImageLink
  return scan
}

export const getScanRefs = async (scan) => {
  const cloudStorage = await getStorage()
  const lensType = scan.lens_type_ref.get().catch((error) => {
    console.error(
      `error getting lensType ${scan.lens_type_ref.id} for scan ${scan._id}: ${error}`
    )
    captureException(error)
    return DEFAULT_REFS.LENS_TYPE
  })
  const lens = scan.lens_ref.get().catch((error) => {
    console.error(
      `error getting lens ${scan.lens_ref.id} for scan ${scan._id}: ${error}`
    )
    captureException(error)
    return DEFAULT_REFS.LENS
  })
  const user = scan.user_ref.get().catch((error) => {
    console.error(
      `error getting user ${scan.user_ref.id} for scan ${scan._id}: ${error}`
    )
    captureException(error)
    // User is no longer a member of the Organization. Calling `.data()` in
    // `associateScanWithRefs()` will return the default Unknown User.
    return DEFAULT_REFS.USER
  })
  const scanner = scan.scanner_ref.get().catch((error) => {
    console.error(
      `error getting scanner ${scan.scanner_ref.id} for scan ${scan._id}: ${error}`
    )
    captureException(error)
    return DEFAULT_REFS.SCANNER
  })
  const externalImageLink =
    scan.reference_external_image_path !== undefined
      ? cloudStorage
          .ref(scan.reference_external_image_path)
          .getDownloadURL()
          .catch((error) => {
            console.error(
              `error getting external image ${scan.reference_external_image_path} for scan ${scan._id}: ${error}`
            )
            captureException(error)
            return undefined
          })
      : null
  return Promise.all([lensType, lens, user, scanner, externalImageLink])
}

export const associateLensWithRefs = (lens, refsArray) => {
  const lensType = refsArray[0].data()

  lens = { ...lensType, ...lens }
  return lens
}

export const getLensRefs = (lens) => {
  const lensType = lens.lens_type_ref.get().catch((error) => {
    console.error(
      `error getting lensType ${lens.lens_type_ref.id} for lens ${lens.id}: ${error}`
    )
    setContext("lens", { lens: lens, lensType: lensType })
    captureException(error)
    return DEFAULT_REFS.LENS_TYPE
  })
  return Promise.all([lensType])
}

// Dynamically fetch app information from the `/__/...` endpoint, which is
// Firebase Hosting's reserved URL: https://firebase.google.com/docs/hosting/reserved-urls
// This means we don't need to change our code to deploy to different projects.
const asyncApp = fetch("/__/firebase/init.json").then(async (response) => {
  const json = await response.json()
  return firebase.initializeApp(json)
})

export const getFunctions = async () => {
  await asyncApp
  return firebase.functions()
}

export const getDb = async () => {
  const app = await asyncApp
  return firebase.firestore(app)
}

export const getStorage = async () => {
  const app = await asyncApp
  return app.storage()
}

export const getAuth = async () => {
  const app = await asyncApp
  const appAuth = app.auth()
  if (
    process.env.REACT_APP_ID === "sandbox" || // skipcq JS-0125
    process.env.REACT_APP_ID === "tracis-infra-sandbox" || // skipcq JS-0125
    window.location.hostname === "app.sandbox.tracis.io"
  ) {
    console.info("Attempting auto login for sandbox default user...")
    await defaultUserSignIn(app)
  }
  return appAuth
}

const defaultUserSignIn = async (app) => {
  app
    .auth()
    .signInWithEmailAndPassword(DEFAULT_USER.email, DEFAULT_USER.password)
    .catch(function (error) {
      console.error("Error signing in sandbox user:", error.code, error.message)
      captureException(error)
    })
  console.info("Default user signed in.")
}

// Create a unique identifier for aliased lenses, since their type_ids
// and serial_numbers are no longer unique identfiers.
export const generateIndex = (length = 24) => {
  return Math.random().toString(36).substr(2, length)
}

export const aliasLenses = (lenses) => {
  let aliasedLenses = []
  lenses.forEach((lens) => {
    if (lens.make === "ARRI") {
      const makeAlias = "Zeiss"
      aliasedLenses.push({
        ...lens,
        make: makeAlias,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
    }
    if (lens.make === "Zeiss" && lens.model.startsWith("Super Speed")) {
      const modelAlias = "High Speed"
      let aliasModel = lens.model.replace("Super Speed", modelAlias)
      aliasedLenses.push({
        ...lens,
        model: aliasModel,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
    }
    if (lens.make === "Leitz / Leica") {
      const makeAlias1 = "Leitz"
      const makeAlias2 = "Leica"
      aliasedLenses.push({
        ...lens,
        make: makeAlias1,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
      aliasedLenses.push({
        ...lens,
        make: makeAlias2,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
      // Since this is pushed as Leitz and Leica we don't need to keep it.
      return
    }
    if (lens.make === "Leica") {
      const makeAlias = "Leitz"
      aliasedLenses.push({
        ...lens,
        make: makeAlias,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
    }
    if (lens.make === "Leitz") {
      const makeAlias = "Leica"
      aliasedLenses.push({
        ...lens,
        make: makeAlias,
        uniqueIdentifier: generateIndex(),
        lenses: undefined,
      })
    }
    aliasedLenses.push({
      ...lens,
      uniqueIdentifier: generateIndex(),
    })
  })
  return aliasedLenses
}

export const describeLensModel = (lens) => {
  if (lens === undefined) return {}
  lens.model = lens.housing_make
    ? `${lens.model}, ${lens.housing_make} housed`
    : lens.model
  lens.focal_length_description =
    lens.focal_length_max !== 0 &&
    lens.focal_length_max !== null &&
    lens.focal_length_max !== undefined &&
    lens.focal_length_max > lens.focal_length_mm
      ? `${lens.focal_length_mm}-${lens.focal_length_max}`
      : `${lens.focal_length_mm}`
  return lens
}

// Sort lenses by make, model, focal length, image diameter, then t-stop.
export const sortLenses = (lenses) => {
  return lenses.sort((lens1, lens2) => {
    if (lens1.make.toLowerCase() > lens2.make.toLowerCase()) return 1
    if (lens1.make.toLowerCase() < lens2.make.toLowerCase()) return -1
    if (lens1.model > lens2.model) return 1
    if (lens1.model < lens2.model) return -1
    if (parseFloat(lens1.focal_length_mm) > parseFloat(lens2.focal_length_mm))
      return 1
    if (parseFloat(lens1.focal_length_mm) < parseFloat(lens2.focal_length_mm))
      return -1
    if (parseFloat(lens1.image_diameter) > parseFloat(lens2.image_diameter))
      return 1
    if (parseFloat(lens1.image_diameter) < parseFloat(lens2.image_diameter))
      return -1
    if (parseFloat(lens1.tstop) > parseFloat(lens2.tstop)) return 1
    if (parseFloat(lens1.tstop) < parseFloat(lens2.tstop)) return -1
    return 0
  })
}

export const associateLensTypeswithLenses = (lensTypes, lenses) => {
  lenses.forEach((lens) => {
    const typeId = lens.lens_type_ref.id
    if (lensTypes[typeId] === undefined) {
      console.error(`No LensType with id ${typeId} for Lens ${lens.lens_id}`)
      return
    }
    if (lensTypes[typeId].lenses === undefined) {
      lensTypes[typeId].lenses = [lens]
    } else {
      lensTypes[typeId].lenses.push(lens)
    }
  })
  return lensTypes
}

// Utility for turning a canvas dataUrl into a blob.
// Used to save react-sketch output as png.
export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const sortTimestamp = (items) => {
  return items.sort((a, b) => {
    if (a.timestamp.seconds > b.timestamp.seconds) return -1
    if (a.timestamp.seconds < b.timestamp.seconds) return 1
    return 0
  })
}
