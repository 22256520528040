import React, { FC } from "react"
import { Tooltip } from "antd"
import { TooltipPropsWithTitle } from "antd/lib/tooltip"

interface Props {
  hideIf: boolean,
  children: object,
  placement: TooltipPropsWithTitle["placement"],
  title: object
}

const HiddenTooltip: FC<Props> = ({ hideIf, children, placement, title }: Props) => {
  const extraProps: {visible?: boolean} = {}
  if (hideIf) extraProps.visible = false

  return (
    <Tooltip placement={placement} title={title} {...extraProps}>
      {children}
    </Tooltip>
  )
}

export default HiddenTooltip
