export const reducer = (state, action) => {
  switch (action.type) {
    case "ALL_LENS_TYPES":
      return { ...state, lensTypes: action.lensTypes }
    case "SET_CURRENT_SCAN_LENS":
      return { ...state, currentScanLens: action.lens }
    case "SET_USER_ORGS_IDS":
      return { ...state, userOrgIds: action.userOrgIds }
    case "SET_USER_ORG_ID":
      console.info(`viewing organization ${action.userOrgId}`)
      return { ...state, userOrgId: action.userOrgId }
    case "SET_SCANS":
      return { ...state, scans: action.scans }
    case "SET_SCANNERS_FOR_ORG":
      return { ...state, scanners: action.scanners }
    case "SET_SCANNER":
      return { ...state, scanner: action.scanner }
    case "RESET_WIZARD":
      return { ...state, scanner: null }
    case "START_ANOTHER_SCAN":
      return { ...state, currentScanLens: null }
    case "SET_SCAN_ID":
      return { ...state, scanID: action.scanID }
    case "SET_DEMO_MODE":
      return { ...state, demoMode: action.demoMode }
    case "SET_SERVICE_MODE":
      return { ...state, serviceMode: action.serviceMode }
    case "SET_NAV_COLLAPSED":
      return { ...state, collapsed: action.collapsed }
    case "SET_BETA_USER":
      return { ...state, BETA_USER: action.betaUserFlag }
    case "SET_INVENTORY_LENS":
      return { ...state, inventoryLens: action.lens }
    case "SET_LOADED":
      return { ...state, loaded: action.loaded }
    case "SET_SHARE_MODAL_VISIBLE":
      return { ...state, shareModalVisible: action.visible }
    case "SET_CALIBRATION_SCAN":
      return { ...state, calibrationScan: action.calibrationScan }
    case "SET_STEP":
      return { ...state, step: action.step }
    case "SET_ADD_LENS_MODAL_VISIBLE":
      return { ...state, addLensModalVisible: action.visible }
    case "SET_UID":
      return { ...state, uid: action.uid }
    case "SET_USER_NAME":
      return { ...state, userName: action.userName }
    case "SET_SANDBOX_FLAG":
      return { ...state, sandbox: action.sandbox }
    case "SET_USER":
      return { ...state, user: action.user }
    case "SET_INVITED_USER":
      return { ...state, invitedUser: action.invitedUser }
    case "SET_TOUR":
      return { ...state, tour: action.tour }
    default:
      throw new Error(
        `Unknown action: ${action.type}. Ensure this action has an associated reducer`
      )
  }
}
