import React from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"
import { Select } from "antd"

const SurfaceSelect = ({
  surface,
  surfaces,
  handleSurfaceFilterChange,
  disabled = false,
}) => {
  const { Option } = Select
  return (
    <Select
      onChange={handleSurfaceFilterChange}
      placeholder="Surface"
      allowClear={false}
      className={css.inventoryFilter}
      dropdownMatchSelectWidth={false}
      key="surfaceFilter"
      value={surface}
      disabled={disabled}
    >
      {surfaces.map((s) => (
        <Option key={s} value={s} className={css.inventoryOption}>
          {s}
        </Option>
      ))}
    </Select>
  )
}

SurfaceSelect.propTypes = {
  /** @type{string} Selected surface name. */
  surface: PropTypes.string,
  /** @type{string[]} Array of available surface name strings. */
  surfaces: PropTypes.array,
  /** @type{Function} Handler for Select surface change. */
  handleSurfaceFilterChange: PropTypes.func,
  /** @type{Boolean} Firebase function to create new user. */
  disabled: PropTypes.bool,
}

export default SurfaceSelect
