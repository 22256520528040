import { getFunctions } from "./helpers"

const functions = {
  onSandboxSalesRequest: async (request) => {
    const firebaseFunctions = await getFunctions()

    const onFirebaseSalesSandboxRequest = firebaseFunctions.httpsCallable(
      "onSandboxSalesRequest"
    )
    onFirebaseSalesSandboxRequest({ text: request }).then((result) =>
      console.log(result)
    )
  },
}

export default functions
