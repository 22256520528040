import { COLLECTION, INSTRUCTION } from "../constants"
import { getDb } from "./helpers"

const scanners = {
  startService: async (orgId, scannerId, author) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.START_SERVICE,
        author_email: author,
      })
  },
  onServiceChange: async (orgId, scannerId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .onSnapshot((snapshot) => {
        const scanner = snapshot.data()
        if (scanner === undefined) return onChange({})
        return onChange({ ...scanner, id: scanner._id })
      })
  },
  stopService: async (orgId, scannerId) => {
    const db = await getDb()
    return await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.STOP_SERVICE,
      })
  },
  onScannersForOrg: async (orgId, onChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .onSnapshot((snapshot) => {
        const scanners = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        onChange(scanners)
      })
  },

  getScanner: async (orgId, scannerId) => {
    const db = await getDb()
    const scanner = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .get()
    return { ...scanner.data(), id: scanner.id }
  },
  onScannerStatusChange: async (orgId, scannerId, onStatusChange) => {
    const db = await getDb()
    return db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.SCANNERS)
      .doc(scannerId)
      .onSnapshot((snapshot) => {
        onStatusChange({ ...snapshot.data(), id: snapshot.id })
      })
  },
}

export default scanners
