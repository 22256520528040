import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Layout, Alert, Button, Modal } from "antd"
import { firestore as db } from "../../../state/firebase"
import ScanWizard from "../../ScanWizard"
import LeftNav from "../LeftNav"
import Error from "../../Error"
import SettingsPage from "../../Settings"
import ScanHistoryPage from "../../ScanHistory"
import ScanNotification from "../ScanNotification"
import Scratches from "../../Scratches"
import { Switch, Route, Redirect } from "react-router-dom"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import SharedLinkViewer from "../../SharedLinkViewer"
import Inventory from "../../Inventory"
import { ACTION_CODE_SETTINGS, DEFAULT_USER } from "../../../state/constants"
import SandboxNotice from "../../Sandbox/SandboxNotice"
import SignUp from "../../common/SignUp"
import * as Sentry from "@sentry/react"

const AppLayout = ({ ...props }) => {
  const { Content } = Layout
  const [state, dispatch] = useGlobalState()
  const [showEmailUnverifiedAlert, setShowEmailUnverifiedAlert] = useState(
    false
  )

  useEffect(() => {
    const domain = props.user.email.split("@")[1]
    const betaUserFlag = ["tracis.eu"].includes(domain)
    dispatch({ type: "SET_BETA_USER", betaUserFlag: betaUserFlag })
    dispatch({ type: "SET_UID", uid: props.user.uid })
    dispatch({ type: "SET_USER_NAME", userName: props.user.displayName })
    if (
      props.user.email === DEFAULT_USER.email &&
      props.user.uid === DEFAULT_USER.uid
    ) {
      dispatch({ type: "SET_SANDBOX_FLAG", sandbox: true })
    }
    db.getUserProfile(props.user.uid).then((firebaseUser) => {
      dispatch({ type: "SET_USER", user: { ...props.user, ...firebaseUser } })
    })
  }, [props.user.email])

  // Block unverified users from accessing anything until they email verify.
  useEffect(() => {
    if (props.user.emailVerified === false && state.sandbox === false) {
      setShowEmailUnverifiedAlert(true)
      return
    }
    setShowEmailUnverifiedAlert()
  }, [props.user.emailVerified, state.sandbox])

  // Update scanners listener upon org change.
  useEffect(() => {
    let unsubscribe
    if (state.userOrgId === undefined) return
    unsubscribe = db.onScannersForOrg(state.userOrgId, (scanners) => {
      dispatch({ type: "SET_SCANNERS_FOR_ORG", scanners: scanners })
    })

    return () => {
      unsubscribe.then((unsubscribeFunction) => unsubscribeFunction())
    }
  }, [state.userOrgId])

  // Reset scan wizard upon org change.
  useEffect(() => {
    dispatch({ type: "SET_SCANNER", scanner: null })
    dispatch({ type: "SET_STEP", step: 0 })
  }, [state.userOrgId])

  const emailUnverifiedMessage = (
    <div>
      Please check your email for a verification link. Your experience will be
      limited until your account is verified.{" "}
      <Button
        type="link"
        onClick={() => props.user.sendEmailVerification(ACTION_CODE_SETTINGS)}
      >
        Resend Email
      </Button>
    </div>
  )

  const registrationModal = (
    <Modal
      maskStyle={{ backdropFilter: "blur(8px)" }}
      visible={state.invitedUser !== false}
      footer={null}
      width={640}
      centered
    >
      <SignUp user={props.user} />
    </Modal>
  )

  useEffect(() => {
    Sentry.setUser(props.user)
  }, [props.user])

  useEffect(() => {
    Sentry.setContext("org", {
      org: state.userOrgId,
    })
  }, [state.userOrgId])

  return (
    <div>
      <Layout className={state.collapsed ? css.collapsed : css.expanded}>
        <LeftNav />
        <Content className="fadeIn">
          {showEmailUnverifiedAlert === true && (
            <Alert
              message={emailUnverifiedMessage}
              type="warning"
              closable
              showIcon
            />
          )}
          <ScanNotification />
          {registrationModal}
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Redirect to={state.sandbox ? "/scan_history" : "/scan"} />
              )}
            />
            <Route path="/scan">
              <ScanWizard {...props} />
            </Route>
            <Route path="/scratches">
              <Scratches />
            </Route>
            <Route path="/inventory">
              <Inventory {...props} />
            </Route>
            <Route path="/settings">
              <SettingsPage user={props.user} {...props} />
            </Route>
            <Route path="/scan_history">
              <ScanHistoryPage {...props} />
            </Route>
            <Route path="/signin">
              <Redirect to="/settings" />
            </Route>
            <Route path="/signup">
              <Redirect to="/settings" />
            </Route>
            <Route path="/shared/:sharingLinkId">
              <SharedLinkViewer {...props} guest={false} />
            </Route>
            <Route path="/error">
              <Error />
            </Route>
          </Switch>
        </Content>
      </Layout>
      {state.sandbox && <SandboxNotice />}
    </div>
  )
}

AppLayout.propTypes = {
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
  /** @type{Function} Call Firebase signOut method and redirect to /signin. */
  signOutAndRedirect: PropTypes.func,
}

export default AppLayout
