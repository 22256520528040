import { COLLECTION } from "../constants"
import { getDb, getAuth } from "./helpers"

// Add orgID to url, move Invites under Organizations
const actionCodeSettings = (inviteId, orgId) => {
  const origin = window.location.origin
  return {
    url: `${origin}/settings?token=${inviteId}&orgId=${orgId}`,
    handleCodeInApp: true,
  }
}

const invites = {
  onOrgInvites: async (orgId, onDone) => {
    let invites = new Set()
    const db = await getDb()
    const orgRef = db.collection(COLLECTION.ORGANIZATIONS).doc(orgId)
    return orgRef
      .collection(COLLECTION.INVITES)
      .onSnapshot(async (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            invites.add(change.doc.data())
          }
          if (change.type === "removed") {
            // Correct way to remove a set item as per MDN.
            invites.forEach((invite) => {
              if (invite._id === change.doc.data()._id) {
                invites.delete(invite)
              }
            })
          }
        })
        onDone([...invites])
      })
  },
  inviteNewOrgUser: async (orgId, email) => {
    const db = await getDb()
    const auth = await getAuth()
    const inviteId = db.collection(COLLECTION.INVITES).doc().id
    const orgRef = db.collection(COLLECTION.ORGANIZATIONS).doc(orgId)
    const acs = actionCodeSettings(inviteId, orgId)
    const inviteData = {
      _id: inviteId,
      email: email,
      newUser: false,
    }
    auth.fetchSignInMethodsForEmail(email).then((arr) => {
      if (arr.length === 0) auth.sendSignInLinkToEmail(email, acs)
      else inviteData.newUser = true
    })
    return await orgRef
      .collection(COLLECTION.INVITES)
      .doc(inviteId)
      .set(inviteData)
  },
  resendEmailInvite: async (inviteId, orgId) => {
    const db = await getDb()
    const auth = await getAuth()
    const acs = actionCodeSettings(inviteId, orgId)
    const invite = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.INVITES)
      .doc(inviteId)
      .get()
    const inviteData = invite.data()
    auth.sendSignInLinkToEmail(inviteData.email, acs)
    return Promise.resolve()
  },
  getInviteByID: async (inviteId, orgId) => {
    const db = await getDb()
    const inviteDoc = await db
      .collection(COLLECTION.ORGANIZATIONS)
      .doc(orgId)
      .collection(COLLECTION.INVITES)
      .doc(inviteId)
      .get()
    return inviteDoc.data()
  },
}

export default invites
