import { firestore } from "firebase"
import { COLLECTION } from "../constants"
import { getDb } from "./helpers"

const users = {
  updateUserProfile: async (uid, displayName) => {
    const db = await getDb()
    const userRef = db.collection(COLLECTION.USERS).doc(uid)
    await userRef.update({ displayName: displayName })
    return await userRef.get().then((doc) => doc.data())
  },
  getUserProfile: async (uid) => {
    const db = await getDb()
    const user = await db.collection(COLLECTION.USERS).doc(uid).get()
    return user.data()
  },
  deleteUser: async (orgId, userId) => {
    const db = await getDb()

    const userRef = db.collection(COLLECTION.USERS).doc(userId)
    const orgRef = db.collection(COLLECTION.ORGANIZATIONS).doc(orgId)
    await orgRef.update({
      users: firestore.FieldValue.arrayRemove(userRef),
    })
    return Promise.resolve()
  },
}

export default users
