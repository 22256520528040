import React from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"

export default function Lens({ lens }) {
  return (
    <div className={css.lensText}>
      <span className={css.make}>{lens.make}</span>
      <span className={css.model}>{`${lens.model} ${
        lens.focal_length_description ||
        lens.focal_length_mm ||
        lens.focal_length
      }mm`}</span>
    </div>
  )
}

Lens.propTypes = {
  /** @type {{make: string, model: string, focal_length_description?: string,
    focal_length_mm?: number, focal_length?: number}} Lens object. */
  lens: PropTypes.object,
}
