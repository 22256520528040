import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Form } from "antd"
import { useGlobalState } from "../../../state"
import { useForm } from "antd/lib/form/Form"
import LensInstanceFields from "../../Details/LensInstanceFields"
import css from "./SerialNumberForm.module.css"

const SerialNumberForm = ({ addNewLens, handleContinue }) => {
  const [state, dispatch] = useGlobalState()
  const [form] = useForm()
  const [fieldValues, setFieldValues] = useState()

  const handleFieldsChange = () => {
    setFieldValues(form.getFieldsValue())
  }

  const handleSubmit = () => {
    addNewLens(fieldValues.serial_number, fieldValues.barcode).then((lens) => {
      // Scan Wizard next steps
      if (handleContinue !== undefined) {
        const csl = { ...state.currentScanLens, ...lens, lens_id: lens.id }
        dispatch({
          type: "SET_CURRENT_SCAN_LENS",
          lens: csl,
        })
        handleContinue(csl)
      } else {
        dispatch({
          type: "SET_CURRENT_SCAN_LENS",
          lens: null,
        })
      }
    })
  }

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  }

  return (
    <Form
      {...formItemLayout}
      onFinish={handleSubmit}
      className={css.serialNumberForm}
      onFieldsChange={handleFieldsChange}
      form={form}
    >
      <LensInstanceFields fieldValues={fieldValues} />
      <Button htmlType="submit" block>
        Continue
      </Button>
    </Form>
  )
}

SerialNumberForm.propTypes = {
  /** @type{Function} Function to add new Lens instance. This is a drilled 
    prop. */
  addNewLens: PropTypes.func,
  /** @type{Function} Handler for "Continue" button click in ScanWizard. This
    is a drilled prop. */
  handleContinue: PropTypes.func,
}

export default SerialNumberForm
