// SOURCE: https://stackoverflow.com/a/59185109

import { useState, useEffect } from "react"

export default function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined"

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  function handleResize() {
    setWindowDimensions(getWindowDimensions())
  }
  useEffect(() => {
    if (hasWindow) {
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}
