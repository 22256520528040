import React, { useEffect, useState } from "react"
import css from "./index.module.css"
import PropTypes from "prop-types"
import { ToolOutlined } from "@ant-design/icons"
import { useGlobalState } from "../../state"
import { firestore as db } from "../../state/firebase"
import UserDetails from "./UserDetails"
import OrgDetails from "./OrgDetails"
import AddOrganization from "./AddOrganization"
import SessionDetails from "./SessionDetails"

export default function Settings({ signOutAndRedirect, user }) {
  const [state] = useGlobalState()
  const [owner, setOwner] = useState(false)
  const [orgsData, setOrgsData] = useState([])
  const [firestoreUser, setFirestoreUser] = useState(user)

  // Get all organization data for a user's orgIds.
  useEffect(() => {
    db.getOrgDetails(state.userOrgIds).then((data) => {
      setOrgsData(data)
    })
  }, [state.userOrgIds])

  // Display and edit Firesetore User document data instead of auth data.
  useEffect(() => {
    db.getUserProfile(user.uid).then((data) => {
      setFirestoreUser(data)
    })
  }, [user])

  // Determine if user is an owner of their Organization. (Their firestore User
  // document's `owner` array of orgIds contains the current orgId.)
  useEffect(() => {
    if (state === undefined) return
    if (state.user === undefined || state.userOrgId === undefined) return
    if (state.user.owned_organizations === undefined) return setOwner(false)
    setOwner(state.user.owned_organizations.includes(state.userOrgId))
  }, [state.user, state.userOrgId])

  return (
    <div className={css.container}>
      <h2>
        <ToolOutlined /> Settings
      </h2>
      <div className={css.module}>
        <SessionDetails
          orgsData={orgsData}
          user={user}
          signOutAndRedirect={signOutAndRedirect}
        />
      </div>
      <div className={css.module}>
        <UserDetails
          user={user}
          firestoreUser={firestoreUser}
          setFirestoreUser={setFirestoreUser}
        />
      </div>
      {orgsData.length === 0 && (
        <div className={css.module}>
          <AddOrganization user={user} />
        </div>
      )}
      {owner && (
        <div className={css.module}>
          <OrgDetails orgsData={orgsData} />
        </div>
      )}
    </div>
  )
}

Settings.propTypes = {
  /** @type{Function} Call Firebase signOut method and redirect to /signin. 
    This is a drilled prop. */
  signOutAndRedirect: PropTypes.func,
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
}
