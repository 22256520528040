import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import lensOutline from "../../images/lens_outline.svg"
import tracisLogoColor from "../../images/tracis_horiz_color_large.png"
import css from "./PrintableLenses.module.css"
import { MODE } from "./CombinedLensModal"
import { toDayMonthTime, generateTimeString, year } from "../../helpers/date"

const Surface = ({ mode, name, images }) => {
  return (
    <div className={cx(css.rearContainer, css[name])}>
      {images.length > 0 ? (
        images.map((image) => (
          <div key={image} className={css.imageContainer}>
            <img
              className={css.surfaceImage}
              alt={`${mode} surface`}
              src={image}
            />
          </div>
        ))
      ) : (
        <div className={cx(css.surfaceImage, css.fullWidth)}>
          <div className={css.noContainer}>{`No ${mode}`}</div>
        </div>
      )}
      <img
        className={cx(css.lensOutline, {
          [css.outlineHidden]: images.length > 0 && mode === MODE.SCANS,
        })}
        src={lensOutline}
        alt="lens outline"
      />
      <span className={css.surfaceLabel}>{name}</span>
    </div>
  )
}

Surface.propTypes = {
  /** @type{"scans" | "scratches"} View scans or scratches on printout. */
  mode: PropTypes.string,
  /** @type{string} Surface name. */
  name: PropTypes.string,
  /** @type{string[]} Array of surface image urls. */
  images: PropTypes.arrayOf(PropTypes.string),
}

const Lens = ({ lens, mode }) => {
  return (
    <div className={css.lensContainer}>
      <header className={css.lensHeader}>{lens.name}</header>
      <div className={css.lens}>
        <div className={css.detailsContainer}>
          <div className={css.focalLength}>{`${lens.focalLength}mm`}</div>
          {lens.timestamp && (
            <div className={css.serialNumber}>{`${toDayMonthTime(
              lens.timestamp.seconds
            )} ${year(lens.timestamp.seconds)} ${generateTimeString(
              lens.timestamp.seconds
            )}`}</div>
          )}
          <div className={css.serialNumber}>{`SN: ${lens.serialNumber}`}</div>
          {lens.notes.length > 0 &&
            lens.notes.map((note) => (
              <div key={note.id} className={css.note}>
                {note.note}
              </div>
            ))}
        </div>
        <Surface mode={mode} name="rear" images={lens.rearImages} />
        <Surface mode={mode} name="front" images={lens.frontImages} />
      </div>
    </div>
  )
}

Lens.propTypes = {
  /** @type{{rearImages: string[], frontImages: string[], notes: {id: string, 
    note: string}[], timetamp: {seconds: number}, name: string, 
    focalLength: string }} Lens object. */
  lens: PropTypes.object,
  /** @type{"scans" | "scratches"} View scans or scratches on printout. */
  mode: PropTypes.string,
}

// PrintableLenses needs to be a class component so it
// plays nice with useReactToPrint.
class PrintableLenses extends Component {
  render() {
    return (
      <div className={css.printableLensWrapper}>
        {this.props[this.props.mode].map((lens, i) => (
          <Fragment key={lens.serialNumber}>
            {i % 3 === 0 && (
              <>
                <div className="page-break" />
                <header className={cx(css.showOnPrintout, css.pageHeader)}>
                  {this.props.customLogoPath ? (
                    <img
                      src={this.props.customLogoPath}
                      className={cx(css.showOnPrintout, css.colorLogo)}
                      alt="Other companies logo"
                    />
                  ) : (
                    <div></div>
                  )}
                  <div
                    className={cx(css.showOnPrintout, css.certifiedByContainer)}
                  >
                    <div className={cx(css.showOnPrintout, css.certifiedBy)}>
                      Certified by
                    </div>
                    <img
                      src={tracisLogoColor}
                      className={cx(css.showOnPrintout, css.tracisLogo)}
                      alt="Tracis Logo"
                    />
                  </div>
                </header>
              </>
            )}
            <Lens lens={lens} {...this.props} />
          </Fragment>
        ))}
      </div>
    )
  }
}

PrintableLenses.propTypes = {
  /** @type{object[]} Array of Scan document data for lens. */
  scans: PropTypes.arrayOf(PropTypes.object),
  /** @type{object[]} Array of Scratch document data for lens. */
  scratches: PropTypes.arrayOf(PropTypes.object),
  /** @type{"scans" | "scratches"} View scans or scratches on printout. */
  mode: PropTypes.string,
  /** @type{string} Path to custom company logo to show on printout. */
  customLogoPath: PropTypes.string,
}

export default PrintableLenses
