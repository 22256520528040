import React, { useState, useEffect } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import css from "./AddLensCard.module.css"
import { firestore as db } from "../../state/firebase"
import { Button, Col, Divider, Modal, Tabs } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import LensTree from "../common/LensTree"
import { useGlobalState } from "../../state"
import AddLens from "../ScanWizard/AddLens"
import LenserHeader from "../common/LenserHeader"

const AddLensCard = ({
  handleContinue,
  scanWizard = false,
  listView = false,
  topPlacement = false,
}) => {
  const [state, dispatch] = useGlobalState()
  const [lensTypes, setLensTypes] = useState(state.lensTypes)
  const [activeKey, setActiveKey] = useState("lens")

  useEffect(() => {
    if (state.lensTypes.length > 0) return
    db.getLensTypes().then((docs) => {
      dispatch({ type: "ALL_LENS_TYPES", lensTypes: docs })
      setLensTypes(docs)
    })
  }, [])

  const handleClick = () => {
    dispatch({ type: "SET_ADD_LENS_MODAL_VISIBLE", visible: true })
  }

  const handleCancel = () => {
    dispatch({ type: "SET_ADD_LENS_MODAL_VISIBLE", visible: false })
    setActiveKey("lens")
  }

  const defaultHandleContinue = () => {
    dispatch({ type: "SET_ADD_LENS_MODAL_VISIBLE", visible: false })
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    setActiveKey("lens")
  }

  const addNewLens = (serialNumber, barcode) => {
    dispatch({ type: "SET_ADD_LENS_MODAL_VISIBLE", visible: false })
    return db.addSerialNumber(
      serialNumber,
      state.currentScanLens.type_id,
      state.userOrgId,
      barcode
    )
  }

  const triggerNewLensForm = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: null })
    setActiveKey("newLensForm")
  }

  const { TabPane } = Tabs
  return (
    <Col
      xs={24}
      sm={listView ? 24 : 12}
      md={listView ? 24 : 8}
      lg={listView ? 24 : 6}
      xl={listView ? 24 : 4}
      xxl={listView ? 24 : 3}
      id="tour-step-10"
    >
      <Modal
        maskStyle={{ backdropFilter: "blur(8px)" }}
        footer={null}
        destroyOnClose={true}
        visible={state.addLensModalVisible}
        onCancel={handleCancel}
        className={css.inventoryAddLens}
        centered
        style={{ height: "calc(50vh + 100px)" }}
      >
        {state.addLensModalVisible && (
          <Tabs
            defaultActiveKey="lens"
            activeKey={activeKey}
            tabBarStyle={{ display: "none" }}
          >
            <TabPane key="lens" tab="Select Lens">
              <LenserHeader />
              <LensTree
                lenses={lensTypes}
                addNewLens={addNewLens}
                triggerNewLensForm={triggerNewLensForm}
                handleContinue={handleContinue || defaultHandleContinue}
                scanWizard={scanWizard}
              />
            </TabPane>
            <TabPane key="newLensForm" tab="New Lens Type">
              <AddLens
                addNewLens={addNewLens}
                handleContinue={handleContinue || defaultHandleContinue}
                scanWizard={scanWizard}
              />
            </TabPane>
          </Tabs>
        )}
      </Modal>
      {listView && !topPlacement && (
        <Divider className={css.divider}>OR</Divider>
      )}
      <Button
        className={cx("addLensTrigger", {
          card: !listView,
          listItem: listView,
        })}
        onClick={handleClick}
      >
        <PlusOutlined />
        <br />
        Add Lens
      </Button>
      {listView && topPlacement && (
        <Divider className={css.divider}>OR</Divider>
      )}
    </Col>
  )
}

AddLensCard.propTypes = {
  /** @type{Boolean} List view or row/column view. */
  listView: PropTypes.bool,
  /** @type{Boolean} If no search input, AddLensCard appears at top of list. If
    search input, it appears at bottom of search results. */
  topPlacement: PropTypes.bool,
  /** @type{Boolean} ScanWizard view or Inventory view. This is a drilled 
    prop. */
  scanWizard: PropTypes.bool,
  /** @type{Function} Continue in ScanWizard. This is a drilled prop. */
  handleContinue: PropTypes.func,
}

export default AddLensCard
