import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Input, Select, Button, AutoComplete, Checkbox, Form } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import cx from "classnames"
import Header from "../../common/Header"
import { useGlobalState } from "../../../state"
import css from "./index.module.css"
import { firestore as db } from "../../../state/firebase"
import {
  LENS_TYPE_FIELDS,
  MOUNTS,
  IMAGE_DIAMETERS,
} from "../../../state/constants"

const { Option } = Select

const defaultImageDiameter = IMAGE_DIAMETERS[1].value
const LTF = LENS_TYPE_FIELDS

// True/False field in Add A Lens form with labels (e.g. Anamorphic/Spherical).
const ToggleFormItem = ({ label, field, handleChange }) => {
  return (
    <Form.Item
      label={label}
      htmlFor={field}
      name={field}
      valuePropName="checked"
    >
      <Checkbox name={field} target={{ name: field }} onChange={handleChange}>
        {label}
      </Checkbox>
    </Form.Item>
  )
}

ToggleFormItem.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  falseLabel: PropTypes.string,
  handleChange: PropTypes.func,
}

// Add A Lens field with autocomplete suggestions (e.g. Make, Glass Make).
const AutoCompleteFormItem = ({
  label,
  field,
  state,
  options,
  handleChange,
  required = false,
}) => {
  const handleSelect = (v) =>
    handleChange({ target: { value: v, name: field } })
  return (
    <Form.Item
      label={label}
      htmlFor={field}
      name={field}
      rules={[
        {
          required: required,
          message: `Please add lens ${label}.`,
        },
      ]}
    >
      {state.currentScanLens !== null && state.currentScanLens[field] ? (
        <Input name={field} value={state.currentScanLens[field]} disabled />
      ) : (
        <AutoComplete
          options={options}
          onChange={handleSelect}
          onSelect={handleSelect}
          name={field}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          autoFocus={field === "make"}
        />
      )}
    </Form.Item>
  )
}

AutoCompleteFormItem.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  state: PropTypes.object,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  required: PropTypes.bool,
}

export default function AddLens({ handleContinue, scanWizard = false }) {
  const [state, dispatch] = useGlobalState()
  const [form] = Form.useForm()
  const [duplicateFound, setDuplicateFound] = useState(false)
  const [foundLens, setFoundLens] = useState({})
  const [zoom, setZoom] = useState(false)
  const [rehoused, setRehoused] = useState(false)
  // Inventory additional fields:
  const [serialNumber, setSerialNumber] = useState(null)
  const [barcode, setBarcode] = useState(null)
  const [lens, setLens] = useState(state.currentScanLens || {})
  const [initialValues, setInitialValues] = useState(
    state.currentScanLens || {}
  )

  const mountOptions = MOUNTS.map((mount) => {
    return { value: mount }
  })

  const handleChange = (e) => {
    const internalLens = { ...lens }
    const key = e.target.name
    // Sanitize input based on field type (e.g. "ARRI " -> "ARRI")
    const field = LTF[key]
    if (field === undefined) {
      throw new Error(`No setter for this field ${key}`)
    }
    let value
    switch (field.type) {
      case "string":
        value = e.target.value.trim()
        break
      case "float":
        value = parseFloat(e.target.value)
        break
      case "int":
        value = parseInt(e.target.value)
        break
      case "bool":
        value = e.target.checked
        break
      default:
        throw new Error("Unrecognized field type")
    }
    internalLens[key] = value

    lensAlreadyInDB(internalLens)
    setLens(internalLens)
  }

  // Generate array of antd DataSourceItemType values for AutoComplete.
  const generateDataSource = (field) => {
    let list = state.lensTypes
    if (lens !== undefined && lens.make !== "" && field === "model") {
      list = list.filter((lt) => lt.make === lens.make)
    }
    const set = [
      ...new Set(
        list
          .map((lt) => lt[field])
          .filter((x) => x !== undefined && x !== null)
          .map((lt) => lt.toString())
          .filter((lt) => lt !== "")
      ),
    ]
      .sort()
      .map((opt) => {
        return { value: opt, label: opt }
      })
    return set
  }

  const compare = (val1, val2) => {
    if (!val1 || !val2) return false
    if (typeof val1 === "string") {
      return val1.toLowerCase().trim() === val2.toLowerCase().trim()
    } else {
      return val1 === val2
    }
  }

  // Compare input make/model/focal length to existing LensTypes to avoid duplicates.
  const lensAlreadyInDB = (lens) => {
    for (let existing of state.lensTypes) {
      if (
        compare(lens.make, existing.make) &&
        compare(lens.model, existing.model) &&
        compare(lens.focal_length_mm, existing.focal_length_mm) &&
        state.currentScanLens === null
      ) {
        setDuplicateFound(true)
        setFoundLens(existing)
        break
      } else {
        setDuplicateFound(false)
      }
    }
  }

  const handleImageDiameterChange = (imageDiameter) => {
    setLens({ ...lens, image_diameter: imageDiameter })
  }

  const disableForm = () => {
    // Modal is destroyed upon close so form does not persist old data.
    dispatch({ type: "SET_ADD_LENS_MODAL_VISIBLE", visible: false })
  }

  const handleSubmit = async () => {
    disableForm()
    let lens_type = undefined
    const typeId = state.currentScanLens.type_id
    if (state.currentScanLens !== undefined && state.currentScanLens !== null) {
      try {
        // Update LensType if it is missing fields that the user is permitted
        // to update...
        lens_type = await db.updateLensType(typeId, lens)
      } catch (err) {
        // ...otherwise just get the lens type data.
        lens_type = await db.getLensType(typeId)
      }
    } else {
      // Add a new lens type.
      lens_type = await db.addLensType(lens)
    }
    let csl = { ...state.currentScanLens, ...lens_type }
    if (state.currentScanLens.serial_number === undefined) {
      await db
        .addSerialNumber(serialNumber, lens_type.id, state.userOrgId, barcode)
        .then((lensDoc) => {
          csl = {
            ...csl,
            ...lensDoc,
            lens_id: lensDoc.id,
          }
        })
    }
    dispatch({
      type: "SET_CURRENT_SCAN_LENS",
      lens: scanWizard ? csl : null,
    })
    handleContinue(csl)
  }

  const handleUseExisting = () => {
    dispatch({ type: "SET_CURRENT_SCAN_LENS", lens: foundLens })
    handleContinue(foundLens)
  }

  const handleSNChange = (e) => {
    setSerialNumber(e.target.value)
  }

  const handleBarcodeChange = (e) => {
    setBarcode(e.target.value)
  }

  const toggleRehoused = () => {
    setRehoused(!rehoused)
  }

  // Disable an input if we already know the value of its field.
  const disableInput = (field) => {
    if (state.currentScanLens === null) return false
    if (
      state.currentScanLens[field] === undefined ||
      state.currentScanLens[field] === null
    ) {
      return false
    }
    return true
  }

  // Styling for side-by-side inputs (e.g. tstop/tstop_max).
  const halfWidth = { display: "inline-block", width: "calc(50% - 12px)" }

  const halfWidthSpacer = (
    <span
      style={{
        display: "inline-block",
        width: "24px",
      }}
    ></span>
  )

  const resetForm = () => {
    // Build up lens object with default and pre-existing values.
    let defaultLens = {}
    Object.keys(LTF).forEach((key) => {
      if (state.currentScanLens && state.currentScanLens[key]) {
        defaultLens[key] = state.currentScanLens[key]
      }
    })
    defaultLens["newLens"] = state.currentScanLens === null
    defaultLens["calibrated_front_rear"] = false
    defaultLens["image_diameter"] = defaultImageDiameter
    setInitialValues(defaultLens)
  }

  useEffect(() => resetForm(), [state.currentScanLens])

  return (
    <div>
      <Header>
        {state.currentScanLens !== null ? "Confirm Lens Details" : "Add A Lens"}
      </Header>
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        initialValues={initialValues}
        scrollToFirstError
      >
        <div className={css.formBody}>
          {/* MAKE  */}
          <AutoCompleteFormItem
            label={LTF.make.title}
            field={LTF.make.id}
            state={state}
            handleChange={handleChange}
            options={generateDataSource(LTF.make.id)}
            required
          />

          {/* MODEL */}
          <AutoCompleteFormItem
            label={LTF.model.title}
            field={LTF.model.id}
            state={state}
            handleChange={handleChange}
            options={generateDataSource(LTF.model.id)}
            required
          />

          <Form.Item label="Zoom">
            <Checkbox onChange={() => setZoom(!zoom)} />
          </Form.Item>

          {/* FOCAL LENGTH */}
          {zoom ? (
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label="Focal Length Min"
                name={LTF.focal_length_mm.id}
                style={halfWidth}
                rules={[
                  {
                    required: true,
                    message: "Please add lens Minimum Focal Length",
                  },
                ]}
              >
                <Input
                  name={LTF.focal_length_mm.id}
                  type="number"
                  min={0}
                  step={0.1}
                  onChange={handleChange}
                  addonAfter="mm"
                  disabled={disableInput(LTF.focal_length_mm.id)}
                />
              </Form.Item>
              {halfWidthSpacer}
              <Form.Item
                label={LTF.focal_length_max.title}
                style={halfWidth}
                name={LTF.focal_length_max.id}
                rules={[
                  {
                    required: true,
                    message: "Please add lens Maximum Focal Length.",
                  },
                ]}
              >
                <Input
                  name={LTF.focal_length_max.id}
                  type="number"
                  min={0}
                  step={0.1}
                  onChange={handleChange}
                  addonAfter="mm"
                  disabled={disableInput(LTF.focal_length_max.id)}
                />
              </Form.Item>
            </Form.Item>
          ) : (
            <Form.Item
              label={LTF.focal_length_mm.title}
              name={LTF.focal_length_mm.id}
              rules={[
                {
                  required: true,
                  message: "Please add lens Focal Length",
                },
              ]}
            >
              <Input
                name={LTF.focal_length_mm.id}
                type="number"
                min={0}
                step={0.1}
                onChange={handleChange}
                addonAfter="mm"
                disabled={disableInput(LTF.focal_length_mm.id)}
              />
            </Form.Item>
          )}

          {/* LENGTH */}
          <Form.Item
            label={LTF.length.title}
            name={LTF.length.id}
            rules={[
              {
                required: true,
                message: "Please add the lens length in millimeters.",
              },
            ]}
          >
            <Input
              name={LTF.length.id}
              type="number"
              min={0}
              step={0.1}
              onChange={handleChange}
              addonAfter="mm"
              disabled={disableInput(LTF.length.id)}
            />
          </Form.Item>

          {/* T-STOP */}
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label={LTF.tstop.title}
              style={halfWidth}
              name={LTF.tstop.id}
              rules={[
                {
                  required: true,
                  message: "Please add a minimum T-Stop.",
                },
              ]}
            >
              <Input
                name={LTF.tstop.id}
                type="number"
                min={0}
                step={0.1}
                onChange={handleChange}
                disabled={disableInput(LTF.tstop.id)}
              />
            </Form.Item>
            {halfWidthSpacer}

            <Form.Item
              label={LTF.tstop_max.title}
              style={halfWidth}
              name={LTF.tstop_max.id}
            >
              <Input
                name={LTF.tstop_max.id}
                type="number"
                min={0}
                step={0.1}
                onChange={handleChange}
                placeholder="Optional"
                disabled={disableInput(LTF.tstop_max.id)}
              />
            </Form.Item>
          </Form.Item>

          {/* F-STOP */}
          <Form.Item label={LTF.fstop.title} name={LTF.fstop.id}>
            <Input
              name={LTF.fstop.id}
              type="number"
              min={0}
              step={0.1}
              onChange={handleChange}
              disabled={disableInput(LTF.fstop.id)}
            />
          </Form.Item>

          {/* MOUNT */}
          <AutoCompleteFormItem
            label={LTF.mount.title}
            field={LTF.mount.id}
            handleChange={handleChange}
            state={state}
            options={mountOptions}
            required
          />

          {/* IMAGE DIAMETER */}
          <Form.Item
            label={LTF.image_diameter.title}
            name={LTF.image_diameter.id}
            rules={[
              {
                required: true,
                message: "Please select an image diameter.",
              },
            ]}
          >
            <Select
              name={LTF.image_diameter.id}
              style={{ width: 120 }}
              onChange={handleImageDiameterChange}
            >
              {IMAGE_DIAMETERS.map((opt) => (
                <Option key={opt.text} value={opt.value}>
                  {opt.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* ANAMORPHIC */}
          <ToggleFormItem
            label={LTF.anamorphic.title}
            field={LTF.anamorphic.id}
            handleChange={handleChange}
          />

          {/* REHOUSING INFORMATION */}
          <ToggleFormItem
            label="Rehoused"
            field="rehoused"
            falseLabel="Factory"
            handleChange={toggleRehoused}
          />
          {rehoused && (
            <AutoCompleteFormItem
              label={LTF.glass_make.title}
              field={LTF.glass_make.id}
              handleChange={handleChange}
              state={state}
              options={generateDataSource(LTF.glass_make.id)}
              required
            />
          )}
          {rehoused && (
            <AutoCompleteFormItem
              label={LTF.housing_make.title}
              field={LTF.housing_make.id}
              handleChange={handleChange}
              state={state}
              options={generateDataSource(LTF.housing_make.id)}
            />
          )}

          {/* SERIAL NUMBER AND BARCODE */}
          {!scanWizard && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="serial_number"
                label="Serial Number"
                style={halfWidth}
                rules={[
                  {
                    required: true,
                    message: "Please add a serial number.",
                  },
                ]}
              >
                <Input onChange={handleSNChange} name="serial_number" />
              </Form.Item>
              {halfWidthSpacer}

              <Form.Item label="Barcode" style={halfWidth} name="barcode">
                <Input
                  onChange={handleBarcodeChange}
                  placeholder="Optional"
                  name="barcode"
                />
              </Form.Item>
            </Form.Item>
          )}
        </div>

        {duplicateFound ? (
          <div className={css.flexButton}>
            <div className={css.button}>
              <div className={css.smallText}>This lens is similar to the</div>
              <div className={cx(css.boldText, css.smallText)}>
                {`${foundLens.make} ${foundLens.model} ${foundLens.focal_length_mm}mm`}
              </div>
              <div className={css.smallText}>already in your database</div>
            </div>
            <div className={css.button}>
              <Button type="primary" ghost onClick={handleUseExisting}>
                <ArrowLeftOutlined style={{ color: "#A288FF" }} />
                Use
              </Button>
            </div>
            <div>
              <Button type="danger" ghost onClick={handleSubmit}>
                Create Anyway
              </Button>
            </div>
          </div>
        ) : (
          <div className={css.buttonGroup}>
            <Button type="primary" ghost htmlType="submit" block>
              {state.currentScanLens ? "Continue" : "Create"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

AddLens.propTypes = {
  /** @type{Function} Handler for "Continue" button click in ScanWizard. This 
    is a drilled prop. */
  handleContinue: PropTypes.func,
  /** @type{Boolean} ScanWizard view or Inventory view. This is a drilled 
    prop. */
  scanWizard: PropTypes.bool,
}
