import Shepherd from "shepherd.js"
import "./tour.css"
import { sleep } from "../../state/firebase/helpers"

const handleScroll = (element) => {
  // const topPos = element.offsetTop
  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  })
}

// Helper function to wait for an element to be visible, then click on it.
export const elementRender = async (elementId, interactive = false) => {
  let targetElement = document.getElementById(elementId)
  let count = 0
  while (targetElement === null && count < 20) {
    targetElement = document.getElementById(elementId)
    count += 1
    await sleep(300)
  }
  if (targetElement === null) return Promise.resolve() // Bail!
  if (interactive) targetElement.click()
  await sleep(300)
  return Promise.resolve()
}

const tour = new Shepherd.Tour({
  defaultStepOptions: {
    classes: "tourItem ant-tooltip",
    cancelIcon: {
      enabled: true,
    },
    scrollTo: true,
    scrollToHandler: handleScroll,
    arrow: true,
    popperOptions: {
      modifiers: [{ name: "offset", options: { offset: [0, 16] } }],
    },
    buttons: [
      {
        text: "End Tour",
        classes: "ant-btn ant-btn-primary ant-btn-background-ghost",
        action: () => {
          tour.cancel()
        },
      },
      {
        text: "Next",
        classes: "ant-btn ant-btn-primary",
        // Default "Next" action that gets the current element's tour ID then
        // wait for the next step's target element to load and click it or its
        // trigger.
        action: async () => {
          const currentStep = tour.getCurrentStep()
          currentStep.hide()
          const currentStepDOMId = currentStep.target.id
          const currentStepId = parseInt(
            currentStepDOMId[currentStepDOMId.length - 1]
          )
          const nextStepTrigger = document.getElementById(
            `tour-step-${currentStepId + 1}-trigger`
          )
          if (nextStepTrigger !== null) {
            nextStepTrigger.click()
          } else {
            document.getElementById(`tour-step-${currentStepId}`).click()
          }

          await elementRender(`tour-step-${currentStepId + 1}`).then(
            async () => {
              return tour.next()
            }
          )
        },
      },
    ],
  },
})

const tourSteps = [
  {
    id: 0,
    title: "View scan reports",
    text: "Check out recent scans and easily view the results online.",
    attachTo: {
      element: "#tour-step-0",
      on: "right",
    },
  },
  {
    id: 1,
    title: "Interactive surface view",
    text:
      "Pan, pinch, and zoom in on the surface of individual lens elements to see detail like never before.",
    attachTo: {
      element: "#tour-step-1",
    },
  },
  {
    id: 2,
    title: "Compare scan surfaces by date",
    text: "See how individual lens elements have changed over time.",
    attachTo: {
      element: "#tour-step-2",
      on: "left",
    },
  },
  {
    id: 3,
    title: "Full-featured lens management",
    text:
      "Ditch paper scratch sheets and view or edit a lens' history from the Tracis platform.",
    attachTo: {
      element: "#tour-step-3 .ant-tabs-nav-list",
      on: "right",
    },
    buttons: [
      {
        text: "End Tour",
        classes: "ant-btn ant-btn-primary ant-btn-background-ghost",
        action: () => {
          tour.cancel()
        },
      },
      {
        text: "Next",
        classes: "ant-btn ant-btn-primary",
        action: async () => {
          const currentStep = tour.getCurrentStep()
          currentStep.hide()
          document.getElementById("tour-step-3-tab-scratches").click()

          await elementRender("tour-step-4").then(async () => {
            return tour.next()
          })
        },
      },
    ],
  },
  {
    id: 4,
    title: "Record scratches from anywhere",
    text: "Any computer or tablet can be used to edit your scratch sheets.",
    attachTo: {
      element: "#tour-step-4",
      on: "left",
    },
    canClickTarget: false,
  },
  {
    id: 5,
    title: "Get creative",
    text: "Use the palette to convey important details.",
    attachTo: {
      element: "#tour-step-5",
      on: "left",
    },
    buttons: [
      {
        text: "End Tour",
        classes: "ant-btn ant-btn-primary ant-btn-background-ghost",
        action: () => {
          tour.cancel()
        },
      },
      {
        text: "Next",
        classes: "ant-btn ant-btn-primary",
        action: async () => {
          const currentStep = tour.getCurrentStep()
          currentStep.hide()
          document.getElementById("tour-step-3-tab-timeline").click()

          await elementRender("tour-step-6").then(async () => {
            return tour.next()
          })
        },
      },
    ],
  },
  {
    id: 6,
    title: "Timeline",
    text:
      "Use the Timeline to keep a written history of the condition of a lens.",
    attachTo: {
      element: "#tour-step-6",
      on: "top",
    },
  },
  {
    id: 7,
    title: "Add Timeline Entry",
    text: "Upload notes, pictures, and files related to a lens' history.",
    attachTo: {
      element: "#tour-step-7",
      on: "bottom",
    },
  },
  {
    id: 8,
    title: "Inventory",
    text: "Manage your inventory",
    attachTo: {
      element: "#tour-step-8",
      on: "right",
    },
  },
  {
    id: 9,
    title: "Search",
    text:
      "Easily find any lens, by searching for barcode, make, model, focal length, or serial number.",
    attachTo: {
      element: "#tour-step-9",
      on: "bottom",
    },
  },
  {
    id: 10,
    title: "Add to inventory",
    text: "Add your lenses to your Tracis inventory with just a few clicks.",
    attachTo: {
      element: "#tour-step-10",
      on: "right",
    },
    buttons: [
      {
        text: "Finish Tour",
        classes: "ant-btn ant-btn-primary ant-btn-background-ghost",
        action: () => {
          tour.cancel()
        },
      },
    ],
  },
]

tour.addSteps(tourSteps)

export default tour
