import React, { useState } from "react"
import PropTypes from "prop-types"
import css from "./index.module.css"
import { Button, Input, Typography, Form } from "antd"
import { EditOutlined } from "@ant-design/icons"
import ResetPassword from "../common/ResetPassword"
import { firestore as db } from "../../state/firebase"
import { useGlobalState } from "../../state"

const { Title } = Typography

const UserDetails = ({ user, firestoreUser, setFirestoreUser }) => {
  const [state] = useGlobalState()
  const [editMode, setEditMode] = useState(false)
  const [userDetailsForm] = Form.useForm()
  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const onFinish = async (e) => {
    const displayName = e.displayName
    db.updateUserProfile(user.uid, displayName).then((data) => {
      setFirestoreUser(data)
    })

    toggleEditMode()
  }

  return (
    <div style={{ width: "100%" }}>
      <Title level={4} className={css.title}>
        User{" "}
        {!editMode && (
          <Button
            icon={<EditOutlined />}
            onClick={toggleEditMode}
            className={css.topLeft}
          ></Button>
        )}
      </Title>
      <Form
        {...layout}
        initialValues={firestoreUser || user}
        name="userDetails"
        onFinish={onFinish}
        form={userDetailsForm}
      >
        <Form.Item
          label="Name"
          name="displayName"
          rules={[
            {
              required: true,
              message: "DisplayName is required",
            },
          ]}
        >
          {editMode ? (
            <Input />
          ) : firestoreUser === undefined ? (
            user.displayName
          ) : (
            firestoreUser.displayName
          )}
        </Form.Item>
        <Form.Item label="Password" {...layout}>
          <ResetPassword user={state.user} />
        </Form.Item>
        {editMode && (
          <Form.Item>
            <Button type="primary" htmlType="submit" ghost>
              Done
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  )
}

UserDetails.propTypes = {
  /** @type{object} Firebase auth User object. This is a drilled prop. */
  user: PropTypes.object,
  /** @type{object} Firestore User object. */
  firestoreUser: PropTypes.object,
  /** @type{Function} Set Firestore user data for parent component useState(). */
  setFirestoreUser: PropTypes.func,
}

export default UserDetails
